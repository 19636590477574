import React, { useState, useEffect, useMemo } from 'react';
import angular from '../../assets/img/icons/lan_slider_Angular1.webp';
import aws from '../../assets/img/icons/lan_slider_AWS1.webp';
import mongodb from '../../assets/img/icons/lan_slider_mongodb1.webp';
import nextjs from '../../assets/img/icons/lan_slider_Nextjs1.webp';
import node from '../../assets/img/icons/lan_slider_nodejs1.webp';
import azure from '../../assets/img/icons/lan_slider_azure1.webp';
import firebase from '../../assets/img/icons/lan_slider_firebase1.webp';
import graphql from '../../assets/img/icons/lan_slider_graphql1.webp';
import postgres from '../../assets/img/icons/lan_slider_postgre1.webp';
import react from '../../assets/img/icons/lan_slider_react1.webp';
import typescript from '../../assets/img/icons/lan_slider_typescript1.webp';
import vuejs from '../../assets/img/icons/lan_slider_vuejs1.webp';
import jquery from '../../assets/img/icons/lan_slider_jquery1.webp';
import lodash from '../../assets/img/icons/lan_slider_lodash1.webp';
import d3js from '../../assets/img/icons/lan_slider_d3js1.webp';
import bootstrap from '../../assets/img/icons/lan_slider_bootstrap1.webp';
import tailwind from '../../assets/img/icons/lan_slider_tailwindcss1.webp';
import svelte from '../../assets/img/icons/lan_slider_svelte1.webp';
import python from '../../assets/img/icons/lan_slider_python1.webp';
import java from '../../assets/img/icons/lan_slider_java1.webp';
import php from '../../assets/img/icons/lan_slider_php1.webp';
import express from '../../assets/img/icons/lan_slider_express1.webp';
import django from '../../assets/img/icons/lan_slider_django1.webp';
import springboot from '../../assets/img/icons/lan_slider_springboot1.webp';
import mysql from '../../assets/img/icons/lan_slider_mysql1.webp';
import sqlite from '../../assets/img/icons/lan_slider_sqlite1.webp';
import googlecloud from '../../assets/img/icons/lan_slider_googlecloud1.webp';
import digital_ocean from '../../assets/img/icons/lan_slider_digital_ocean1.webp';
import docker from '../../assets/img/icons/lan_slider_docker1.webp';
import rubyonrails from '../../assets/img/icons/lan_slider_ruby.webp';
import go from '../../assets/img/icons/lan_slider_go.webp';
import aspnet from '../../assets/img/icons/lan_slider_asp.net.webp';
import rust from '../../assets/img/icons/lan_slider_rust.webp';
import ember from '../../assets/img/icons/lan_slider_ember.webp';
import pug from '../../assets/img/icons/lan_slider_pug.webp';
import html from '../../assets/img/icons/lan_slider_html.webp';
import css from '../../assets/img/icons/lan_slider_css.webp';
import redis from '../../assets/img/icons/lan_slider_redis.webp';
import mariadb from '../../assets/img/icons/lan_slider_mariadb.webp';
import mssqlserver from '../../assets/img/icons/lan_slider_microsoft_sql_server.webp';
import kubernetes from '../../assets/img/icons/lan_slider_Kubernetes.webp';
import terraform from '../../assets/img/icons/lan_slider_teraform.webp';
import ansible from '../../assets/img/icons/lan_slider_ansible.webp';
import openshift from '../../assets/img/icons/lan_slider_openshift.webp';
import github from '../../assets/img/icons/lan_slider_github.webp';
import git from '../../assets/img/icons/lan_slider_git.webp';
import bitbucket from '../../assets/img/icons/lan_slider_bitbucket.webp';
import jenkins from '../../assets/img/icons/lan_slider_jenkins.webp';
import travisci from '../../assets/img/icons/lan_slider_travisCI.webp';
import postman from '../../assets/img/icons/lan_slider_postman.webp';
import swagger from '../../assets/img/icons/lan_slider_swagger.webp';
import figma from '../../assets/img/icons/lan_slider_figma.webp';
import adobeXD from '../../assets/img/icons/lan_slider_Adobexd.webp';
import vscode from '../../assets/img/icons/lan_slider_vscode.webp';
import xcode from '../../assets/img/icons/lan_slider_xcode.webp';

import '../../assets/img/experimental/fonts/webfonts/syne/stylesheet.css';
import shape1 from '../../assets/img/experimental/home-9-bg-shape-1.svg';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const technologies = {
  Frontend: [
    { name: 'React', img: react },
    { name: 'Angular', img: angular },
    { name: 'Next.js', img: nextjs },
    { name: 'Vue.js', img: vuejs },
    { name: 'JQuery', img: jquery },
    { name: 'Lodash', img: lodash },
    { name: 'D3.js', img: d3js },
    { name: 'Bootstrap', img: bootstrap },
    { name: 'Tailwind', img: tailwind },
    { name: 'Svelte', img: svelte },
    { name: 'Ember.js', img: ember },
    { name: 'Pug', img: pug },
    { name: 'HTML5', img: html },
    { name: 'CSS3', img: css },
  ],
  Backend: [
    { name: 'Node.js', img: node },
    { name: 'GraphQL', img: graphql },
    { name: 'TypeScript', img: typescript },
    { name: 'Python', img: python },
    { name: 'Java', img: java },
    { name: 'PHP', img: php },
    { name: 'Express', img: express },
    { name: 'Django', img: django },
    { name: 'Spring Boot', img: springboot },
    { name: 'Ruby on Rails', img: rubyonrails },
    { name: 'Go', img: go },
    { name: 'ASP.NET', img: aspnet },
    { name: 'Rust', img: rust },
  ],
  Database: [
    { name: 'MongoDB', img: mongodb },
    { name: 'Firebase', img: firebase },
    { name: 'PostgreSQL', img: postgres },
    { name: 'MySQL', img: mysql },
    { name: 'SQLite', img: sqlite },
    { name: 'Redis', img: redis },
    { name: 'MariaDB', img: mariadb },
    { name: 'Microsoft SQL Server', img:mssqlserver },
  ],
  Infrastructure: [
    { name: 'Amazon Web Service', img: aws },
    { name: 'Microsoft Azure', img: azure },
    { name: 'Google Cloud', img: googlecloud },
    { name: 'Digital Ocean', img:digital_ocean},
    { name:'Docker',img:docker},
    { name:'Kubernetes',img:kubernetes},
    { name:'Terraform',img:terraform},
    { name:'Ansible',img:ansible},
    { name:'OpenShift',img:openshift},
  ],
  Tools:[
      {name:'Git' ,img : git}, // Version control system 
      {name:'GitHub' ,img : github}, // Code hosting platform 
      {name:'Bitbucket' ,img : bitbucket}, // Code hosting platform 
      {name:'Jenkins' ,img : jenkins}, // Continuous integration tool 
      {name:'Travis CI' ,img : travisci}, // Continuous integration service 
      {name:'Postman' ,img : postman}, // API testing tool 
      {name:'Swagger' ,img : swagger}, // API documentation tool 
      {name:'Figma' ,img : figma}, // UI/UX design tool 
      {name:'Adobe XD' ,img : adobeXD}, // UI/UX design tool 
      {name:'VS Code' ,img : vscode}, // UI/UX design tool 
      {name:'X Code' ,img : xcode}, // UI/UX design tool 
      ]  
};

const Languagesslider = () => {
  const [selectedOption, setSelectedOption] = useState('Frontend');

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const animateElement = (selector, delay) => {
      gsap.set(selector, { y: 50, opacity: 0 });
      gsap.to(selector, {
        scrollTrigger: {
          trigger: selector,
          start: "top bottom",
        },
        y: 0,
        opacity: 1,
        duration: 1.5,
        delay,
        ease: "power2.out"
      });
    };

    animateElement("#title", 0.2);
    animateElement("#option", 0.3);
    animateElement("#itemlogo", 0.3);
  }, []);

  const memoizedTechnologies = useMemo(() => technologies[selectedOption], [selectedOption]);

  return (
    <section className="section-service bg-[#F7F7F7] shadow-xl">
      <div className="relative z-10">
        <div className="section-space">
          <div className="container-default">
            <div className='pt-10 pb-10'>
              <h2 className="mb-12 text-center font-Syne text-4xl font-semibold leading-[1.14] -tracking-[1px] text-ColorOil lg:text-5xl xl:text-[56px]" id='title'>
                Technologies We Work With
              </h2>
              <div className='flex justify-center bg-transparent overflow-auto px-2'>
                <div className="jos mb-10 flex flex-wrap justify-center gap-x-6 lg:mb-[60px]">
                  {Object.keys(technologies).map(option => (
                    <button 
                      key={option} 
                      className={`process-tab tab-button ${selectedOption === option ? 'active' : ''}`} 
                      onClick={() => setSelectedOption(option)}
                      aria-label={`Search`}
                    >
                      <span id='option'>{option}</span> 
                    </button>
                  ))}
                </div>
              </div>

              <div className='flex flex-wrap justify-center gap-2 sm:gap-4'>
                {memoizedTechnologies.map((tech, index) => (
                  <div 
                    key={index} 
                    className='w-40 h-40 sm:w-48 sm:h-48 shadow-xl rounded-2xl hover:shadow-md transition ease-in-out duration-100 cursor-default flex items-center justify-center' 
                    id='itemlogo'
                  >
                    <img src={tech.img} alt={tech.name} className='w-24 h-auto object-contain' loading="lazy" />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="absolute left-0 top-0 -z-10">
            <img className='opacity-30 sm:opacity-50 lg:opacity-100' src={shape1} alt="Background shape" width="345" height="337" loading="lazy" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Languagesslider;