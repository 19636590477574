import React, { useEffect, useState, useRef } from "react";
import { Link, Outlet } from "react-router-dom";
import CodeLamdaLogo from "../../assets/img/logos/black.webp";
import "../../style.css";
import Footer from "./Footer";

import { FaBriefcase } from "react-icons/fa6";
import { MdArticle } from "react-icons/md";
import { FaLaptopCode } from "react-icons/fa";

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const MemoizedFaBriefcase = React.memo(FaBriefcase);
  const MemoizedMdArticle = React.memo(MdArticle);
  const MemoizedFaLaptopCode = React.memo(FaLaptopCode);
  

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth < 992);
    };

    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isMenuOpen &&
        !event.target.closest(".menu-block-wrapper") &&
        !event.target.closest(".hamburger-menu")
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  // nav list dropdown starts

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownButtonRef.current &&
      !dropdownButtonRef.current.contains(event.target) &&
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // nav list dropdown ends



  return (
    <>
      <header
        className={`site-header site-header--absolute fixed py-1 transition ease-in-out duration-500 ${isScrolled ? "bg-[#F7F7F7]/95 shadow-xl" : "bg-transparent"
          }`}
        id="sticky-menu"
      >
        <div className="container-default">
          <div className="flex items-center justify-between gap-x-7">
            <Link to={"/"} className="flex-shrink-0">
              <img
                src={CodeLamdaLogo}
                alt="Navbar Logo"
                width="140px"
                height='53px'
                className="min-w-[140px] min-h-[53px] sm:w-[200px]  py-2"
              />
            </Link>

            <div className="flex items-center justify-between w-full">
              {!isMobileView && (
                <nav className="menu-block ml-auto" id="append-menu-header">
                  <ul
                    className="site-menu-main flex justify-end"
                    style={{ columnGap: "35px" }}
                  >
                    <li className="nav-item">
                      <Link to={"/"} className="nav-link-item drop-trigger">
                        <p className="font-Syne text-xl hover:text-[#53818B]">
                          Home
                        </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/service"}
                        className="nav-link-item drop-trigger"
                      >
                        <p className="font-Syne text-xl hover:text-[#53818B]">
                          Services
                        </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/contact"}
                        className="nav-link-item drop-trigger"
                      >
                        <p className="font-Syne text-xl hover:text-[#53818B]">
                          Contact
                        </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/portfolio"}
                        className="nav-link-item drop-trigger"
                      >
                        <p className="font-Syne text-xl hover:text-[#53818B]">
                        Portfolio
                        </p>
                      </Link>
                    </li>




                    {/* dropdown button starts */}
                    <li className="nav-item relative group">
                      <button
                        ref={dropdownButtonRef}
                        onClick={toggleDropdown}
                        aria-label={"Open"}
                        className="font-Syne font-semibold text-xl py-2 text-center inline-flex items-center transition-colors duration-200 hover:text-[#53818B]"
                      >
                        Company
                        <i className="fas fa-chevron-down ml-2 text-sm transition-transform duration-200 ease-in-out group-hover:text-[#53818B]" style={{ color: "#52818b", transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0)' }}></i>
                      </button>

                      {isDropdownOpen && (
                        <div
                          ref={dropdownMenuRef}
                          className="absolute right-0 mt-4 w-64 bg-[#F7F7F7] rounded-2xl rounded-tr-none shadow-2xl py-0 z-10 transform transition-all duration-300 ease-in-out overflow-hidden"
                          style={{ top: "100%" }}
                        >
                          <Link
                            to={'/career'}
                            onClick={toggleDropdown}
                            className="block px-6 py-3 pt-4 text-base text-gray-700 hover:bg-[#53818B] hover:text-white transition-all duration-200 flex items-center"
                          >
                            <MemoizedFaBriefcase className="mr-4 text-xl transition-all duration-200" />
                            <span className="font-semibold font-Syne">Career</span>
                          </Link>
                          <Link
                            to={'/blogs'}
                            onClick={toggleDropdown}
                            className="block px-6 py-3 text-base text-gray-700 hover:bg-[#53818B] hover:text-white transition-all duration-200 flex items-center"
                          >
                            <MemoizedMdArticle className="mr-4 text-xl transition-all duration-200" />
                            <span className="font-semibold font-Syne">Blog</span>
                          </Link>
                          <div className="border-t border-gray-100 my-1"></div>
                          <Link
                            to={'/about'}
                            onClick={toggleDropdown}
                            className="block px-6 py-3 text-base text-gray-700 hover:bg-[#53818B] hover:text-white transition-all duration-200 flex items-center"
                          >
                            <MemoizedFaLaptopCode className="mr-4 text-xl transition-all duration-200" />
                            <span className="font-semibold font-Syne">About Us</span>
                          </Link>
                        </div>
                      )}
                    </li>
                    {/* dropdone button ends */}




                  </ul>
                </nav>
              )}
            </div>

            <div className="flex items-center gap-6">
              <div className="block lg:hidden">
                <button
                  id="openBtn"
                  className="hamburger-menu mobile-menu-trigger"
                  onClick={toggleMenu}
                  aria-label={"Open"}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>

              {isMobileView && (
                <div
                  className={`menu-block-wrapper ${isMenuOpen ? "open" : ""}`}
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <ul className="site-menu-main">
                    <li className="nav-item">
                      <Link
                        to={"/"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/service"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/portfolio"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        Portfolio
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/blogs"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        Blogs
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/about"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        About
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/contact"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        Contact
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/career"}
                        className="nav-link-item"
                        style={{ padding: "0px" }}
                        onClick={toggleMenu}
                      >
                        Career
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      <Outlet />
      <Footer />
    </>
  );
};

export default Navbar;