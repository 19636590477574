import HeaderTitle from "../components/common/HeaderTitle";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import clock from '../assets/img/icons/career/clock.webp'
import location from '../assets/img/icons/career/location.webp'
import rupees from '../assets/img/icons/career/rupees.webp'
import axios from "axios";

const Career = () => {
  const [jobpost, setjobpost] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}job/getjobs`, {});
        if (response.data.success) {
          setjobpost(response.data.jobs);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  function truncateText(text, maxWords) {
    const wordsArray = text.split(' ');
    if (wordsArray.length > maxWords) {
      return `${wordsArray.slice(0, maxWords).join(' ')}...`;
    }
    return text;
  }

  return (
    <>
      <HeaderTitle title={"Career"} />
      <div className="section-careers">
        <div className="section-space bg-[#F7F7F7]">
          <div className="container-default relative z-[11]">

            <div className="jos mb-[60px] flex flex-wrap justify-center gap-10 text-center lg:mb-20 xl:justify-between xl:text-left ">
              <div>
                <h2 className="mb-8 text-center font-Syne font-semibold leading-[1.14] -tracking-[1px] text-5xl">
                  Curently open positions
                </h2>
              </div>

              <div className="flex flex-wrap justify-center gap-4">
                <button className="tab-button btn tab-btn-blue is-rounded active h-[50px] w-[80px] text-center p-0 " data-tab="all">
                  All
                </button>
              </div>
            </div>



            <div className={`tab-content ${jobpost.length > 0 ? 'grid gap-6 md:grid-cols-2' : 'flex items-center justify-center h-28'}`} id="all">
              {loading ? (
                <div className="flex items-center justify-center">
                  <h2 className="text-3xl font-Syne font-semibold leading-[1.14] -tracking-[1px]">
                    Loading jobs...
                  </h2>
                </div>
              ) : jobpost.length > 0 ? (
                jobpost.map((item) => {
                  return (
                    <div key={item._id} className="jos" data-jos_delay="0">
                      <div className="rounded-[10px] border border-[#52818B] bg-white transition-all duration-300 p-7 md:p-10 min-h-[250px] max-h-max">
                        <div className="mb-[15px] text-xl font-semibold tracking-tighter md:text-2xl ">
                          {item.title}
                        </div>
                        <p className="mb-0">
                          {truncateText(item.description, 9)}
                        </p>
                        <div className="my-5 flex flex-wrap gap-x-6 gap-y-3 md:my-[30px]">
                          <div className="flex items-center gap-1">
                            <img src={clock} className="w-[22px] h-auto" alt="Clock Icon" />
                            {item.employee_type}
                          </div>

                          <div className="flex items-center gap-1">
                            <img src={location} className="w-[22px] h-auto" alt="Location Icon" />
                            {item.type === 'remote' ? "Remote" : (item.type === 'hybrid' ? 'Hybrid' : 'Office')}
                          </div>

                          <div className="flex items-center">
                            <img src={rupees} className="h-[20px] w-auto" alt="Salary Icon" />
                            {item.salary?.min && item.salary?.max && (
                              <>
                                {`${Math.floor(item.salary.min / 1000)}k - ${Math.floor(item.salary.max / 1000)}k`}
                              </>
                            )}
                          </div>
                        </div>

                        <Link
                          to="/careerDetails"
                          state={item}
                          className="inline-flex items-center gap-x-2 text-base font-bold cursor-pointer duration-250 border border-[#52818B] px-5 py-2 rounded-lg hover:bg-black/90 hover:border-[#52818B] hover:text-white"
                        >
                          View Details
                          <span className="ease-in-out group-hover:translate-x-2 duration-100">
                            <i className="fa-solid fa-arrow-right"></i>
                          </span>
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex items-center justify-center">
                  <h2 className="text-3xl font-Syne font-semibold leading-[1.14] -tracking-[1px]">
                    Sorry, we are not hiring at this time.
                  </h2>

                </div>
              )}
            </div>


            <div className="jos mt-10 flex justify-center lg:mt-20">
              <Link
                to={"/contact"}
                className="btn bg-[#53818B] border-[#53818B] hover:border-[#000] text-white hover:text-black is-rounded btn-animation is-large group"
              >
                <span>Can't find your role? Contact us</span>
              </Link>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Career;