import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import experiencebadge from "../../assets/img/elements/badge.svg";
import webicon from "../../assets/img/elements/whycodeLamda.svg";
import "../../assets/img/experimental/fonts/webfonts/syne/stylesheet.css";

const Whyushome = () => {
  // useeffect for animation starts
  useEffect(() => {
    //animation starts
    gsap.registerPlugin(ScrollTrigger);
    gsap.set("#whyusimage", { x: 50, opacity: 0 });
    gsap.set("#whyustext", { x: -50, opacity: 0 });
    gsap.set("#whyuslist", { x: -50, opacity: 0 });

    gsap.to("#whyusimage", {
      scrollTrigger: "#whyusimage",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
    gsap.to("#whyustext", {
      scrollTrigger: "#whyustext",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
    gsap.to("#whyuslist", {
      scrollTrigger: "#whyuslist",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.3,
      ease: "power2.out",
    });
  }, []);
  // useeffect for animation ends

  return (
    <>
      <section className="section-content bg-[#F7F7F7]">
        <div className="horizontal-line bg-[#252525]"></div>

        <div className="relative z-10">
          <div className="section-space relative z-[11]">
            <div
              className="container-custom"
              style={{ paddingRight: "15px", paddingLeft: "5px" }}
            >
              <div className="flex flex-col gap-10 lg:mx-10 ms-5">
                <div className="grid items-center lg:grid-cols-2 xl:grid-cols-[1fr_minmax(0,_1.2fr)] ">
                  {/* right image starts */}
                  <div
                    className="jos relative lg:order-2"
                    data-jos_animation="fade-right"
                  >
                    <div className="rounded-[10px] p-[30px] lg:p-10 xl:p-[50px]">
                      <img
                        src={webicon}
                        alt="content-img-1"
                        // width="426"
                        // height="398"
                        className="h-auto w-[95%] scale-125 rounded-[10px]"
                        id="whyusimage"
                      />
                    </div>
                  </div>
                  {/* right image ends */}

                  {/* left content starts */}
                  <div
                    className="jos lg:order-1"
                    data-jos_animation="fade-left"
                  >
                    <div id="whyustext">
                      <div className="mb-5">
                        <h4 className="mb-3 text-3xl border-l-4 border-[#52818B] pl-3 font-Syne font-medium">
                          Why
                          <span className="text-[#52818B]">
                            {" "}
                            Codelamda Technology{" "}
                          </span>{" "}
                          is the Best Leading Software Development Company
                        </h4>
                        <p className="text-2xl">
                          We’re Committed to Delivering the best quality of
                          development for your businesses
                        </p>
                      </div>
                    </div>

                    <ul
                      className="space-y-4 text-sm text-ColorBlack ps-5 mb-3"
                      id="whyuslist"
                    >
                      <li className="flex items-start">
                        <span className="mr-3 inline-block text-xl text-[#52818B]">
                          <i className="fa fa-check"></i>
                        </span>
                        <p className="flex-1 text-base">
                          At codeLamda Technologies, we not only develop apps
                          but also turn your unique ideas into successful
                          products. Our skilled and experienced team is capable
                          of providing exceptional services.
                        </p>
                      </li>
                      <li className="flex items-start">
                        <span className="mr-3 inline-block text-xl text-[#52818B]">
                          <i className="fa fa-check"></i>
                        </span>
                        <p className="flex-1 text-base">
                          Our primary focus is customer satisfaction, and our
                          goal is to deliver solutions that surpass our clients'
                          expectations.
                        </p>
                      </li>
                      <li className="flex items-start">
                        <span className="mr-3 inline-block text-xl text-[#52818B]">
                          <i className="fa fa-check"></i>
                        </span>
                        <p className="flex-1 text-base">
                          We assess the solution's performance together with the
                          client, ensuring it meets their operational needs and
                          business goals.
                        </p>
                      </li>
                      <li className="flex items-start">
                        <span className="mr-3 inline-block text-xl text-[#52818B]">
                          <i className="fa fa-check"></i>
                        </span>
                        <p className="flex-1 text-base">
                          Customer-Centricity, Collaboration, Innovation,
                          Excellence, Integrity are our core values.
                        </p>
                      </li>
                    </ul>

                    <div id="whyuslist" className="flex items-center mt-9">
                      <Link to={"/about"}>
                        <button className="btn bg-[#52818B] border-[#52818B] hover:border-[#000] text-white hover:text-black is-rounded btn-animation group mr-5" aria-label="Open">
                          <span>Know more</span>
                        </button>
                      </Link>

                      <span className="mr-3 ml-3 inline-block text-xl text-[#52818B]">
                        <img
                          src={experiencebadge}
                          style={{ filter:"invert(40%) sepia(30%) saturate(600%) hue-rotate(150deg) brightness(90%) contrast(85%)" }}
                          alt=""
                        />
                      </span>

                      <p className="flex-1 text-base">8+ Years Experience</p>
                    </div>
                  </div>
                  {/* left content ends */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Whyushome;
