import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import axios from 'axios';

import portfolioImg1 from '../../assets/img/portfolio_home_1.jpg';
import portfolioImg2 from '../../assets/img/portfolio_main_2.jpg';
import portfolioImg3 from '../../assets/img/portfolio_main_3.webp';


const projects = [
  {
    id: 1,
    name: "VCP FITNESS",
    description: "VCP Fitness offers personalized workout modules tailored to individual needs. With in-app purchases available for monthly and yearly memberships, it also features dedicated sections for diet planning and community engagement.",
    image: portfolioImg2,
    features: [
      "Personalized workout modules",
      "In-app purchases for memberships",
      "Diet planning section",
      "Community engagement features"
    ]
  },
  {
    id: 2,
    name: "E-COMMERCE PLATFORM",
    description: "A robust e-commerce platform with advanced features like real-time inventory management, multi-vendor support, and AI-powered product recommendations.",
    image: portfolioImg3,
    features: [
      "Real-time inventory management",
      "Multi-vendor support",
      "AI-powered product recommendations",
      "Secure payment gateway integration"
    ]
  },
  {
    id: 3,
    name: "SMART HOME AUTOMATION",
    description: "An IoT-based smart home automation system that allows users to control various home appliances and monitor energy consumption through a mobile app.",
    image: portfolioImg1,
    features: [
      "Remote device control",
      "Energy consumption monitoring",
      "Voice command integration",
      "Customizable automation routines"
    ]
  },
  {
    id: 4,
    name: "TELEMEDICINE PLATFORM",
    description: "A comprehensive telemedicine platform enabling virtual consultations, secure medical record sharing, and integrated pharmacy services.",
    image: portfolioImg2,
    features: [
      "Video consultation",
      "Electronic health records",
      "E-prescriptions",
      "Appointment scheduling"
    ]
  },
  {
    id: 5,
    name: "FINTECH MOBILE APP",
    description: "A feature-rich fintech mobile app offering personal finance management, investment tracking, and AI-driven financial advice.",
    image: portfolioImg3,
    features: [
      "Expense tracking",
      "Investment portfolio management",
      "Robo-advisory services",
      "Budgeting tools"
    ]
  },
  {
    id: 6,
    name: "AUGMENTED REALITY EDUCATION",
    description: "An educational platform leveraging augmented reality to create immersive learning experiences for various subjects.",
    image: portfolioImg1,
    features: [
      "Interactive 3D models",
      "Virtual lab experiments",
      "Gamified learning modules",
      "Progress tracking"
    ]
  },
  {
    id: 7,
    name: "BLOCKCHAIN SUPPLY CHAIN",
    description: "A blockchain-based supply chain management system ensuring transparency, traceability, and efficiency in logistics operations.",
    image: portfolioImg2,
    features: [
      "Real-time tracking",
      "Smart contract integration",
      "Decentralized record-keeping",
      "Automated compliance checks"
    ]
  },
  {
    id: 8,
    name: "AI-POWERED CHATBOT",
    description: "An advanced AI chatbot capable of handling customer inquiries, processing orders, and providing personalized recommendations across multiple platforms.",
    image: portfolioImg3,
    features: [
      "Natural language processing",
      "Multi-platform integration",
      "Sentiment analysis",
      "Learning capabilities"
    ]
  },
  {
    id: 9,
    name: "VIRTUAL EVENT PLATFORM",
    description: "A comprehensive virtual event platform supporting large-scale online conferences, webinars, and networking sessions.",
    image: portfolioImg1,
    features: [
      "Live streaming",
      "Virtual booths",
      "Networking lounges",
      "Analytics dashboard"
    ]
  },
  {
    id: 10,
    name: "SUSTAINABLE ENERGY MANAGEMENT",
    description: "An intelligent energy management system for businesses and homes, optimizing energy consumption and integrating renewable sources.",
    image: portfolioImg2,
    features: [
      "Real-time energy monitoring",
      "Predictive maintenance",
      "Renewable energy integration",
      "Carbon footprint tracking"
    ]
  },
  {
    id: 11,
    name: "SOCIAL MEDIA ANALYTICS TOOL",
    description: "A powerful social media analytics tool providing in-depth insights, sentiment analysis, and trend forecasting for marketing professionals.",
    image: portfolioImg3,
    features: [
      "Cross-platform data aggregation",
      "Sentiment analysis",
      "Influencer identification",
      "Custom report generation"
    ]
  },
  {
    id: 12,
    name: "SMART CITY MANAGEMENT",
    description: "An integrated smart city management platform coordinating various urban services like traffic management, waste collection, and public safety.",
    image: portfolioImg1,
    features: [
      "IoT sensor integration",
      "Real-time data visualization",
      "Predictive maintenance",
      "Emergency response coordination"
    ]
  },
  {
    id: 13,
    name: "DIGITAL TWIN SIMULATION",
    description: "A sophisticated digital twin simulation platform for industrial applications, enabling virtual testing and optimization of manufacturing processes.",
    image: portfolioImg2,
    features: [
      "Real-time data synchronization",
      "Predictive maintenance",
      "Process optimization",
      "Virtual scenario testing"
    ]
  },
  {
    id: 14,
    name: "CYBERSECURITY DEFENSE SYSTEM",
    description: "An advanced cybersecurity defense system utilizing AI and machine learning to detect and mitigate threats in real-time.",
    image: portfolioImg3,
    features: [
      "AI-powered threat detection",
      "Automated incident response",
      "Vulnerability assessment",
      "Compliance management"
    ]
  },
  {
    id: 15,
    name: "COLLABORATIVE WORKSPACE PLATFORM",
    description: "A comprehensive digital workspace platform facilitating remote collaboration, project management, and team communication.",
    image: portfolioImg1,
    features: [
      "Real-time document collaboration",
      "Video conferencing",
      "Task management",
      "Integration with productivity tools"
    ]
  },
  {
    id: 16,
    name: "AUTONOMOUS VEHICLE NAVIGATION",
    description: "An advanced navigation system for autonomous vehicles, incorporating AI, computer vision, and sensor fusion technologies.",
    image: portfolioImg2,
    features: [
      "Real-time object detection",
      "Path planning algorithms",
      "V2X communication",
      "HD mapping integration"
    ]
  },
  {
    id: 17,
    name: "PERSONALIZED LEARNING PLATFORM",
    description: "An adaptive learning platform that tailors educational content and assessments based on individual student performance and learning styles.",
    image: portfolioImg3,
    features: [
      "AI-driven content recommendation",
      "Personalized learning paths",
      "Real-time progress tracking",
      "Interactive assessments"
    ]
  },
  {
    id: 18,
    name: "DRONE DELIVERY SYSTEM",
    description: "An end-to-end drone delivery system for urban environments, including route optimization, obstacle avoidance, and automated package handling.",
    image: portfolioImg1,
    features: [
      "Autonomous flight control",
      "Real-time route optimization",
      "Secure package handling",
      "Integration with e-commerce platforms"
    ]
  },
  {
    id: 19,
    name: "QUANTUM COMPUTING SIMULATOR",
    description: "A quantum computing simulator designed for researchers and developers to test and optimize quantum algorithms without access to physical quantum hardware.",
    image: portfolioImg2,
    features: [
      "Multiple qubit simulation",
      "Quantum circuit design",
      "Error correction modeling",
      "Integration with classical computing frameworks"
    ]
  },
  {
    id: 20,
    name: "PREDICTIVE MAINTENANCE PLATFORM",
    description: "An industrial IoT platform for predictive maintenance, utilizing machine learning to forecast equipment failures and optimize maintenance schedules.",
    image: portfolioImg3,
    features: [
      "Sensor data analysis",
      "Failure prediction models",
      "Maintenance scheduling optimization",
      "Integration with ERP systems"
    ]
  },
  {
    id: 21,
    name: "AUGMENTED REALITY NAVIGATION",
    description: "An augmented reality navigation app providing immersive, real-time directions and information overlay for pedestrians and drivers.",
    image: portfolioImg1,
    features: [
      "AR route visualization",
      "Points of interest highlighting",
      "Real-time traffic updates",
      "Voice-guided navigation"
    ]
  },
  {
    id: 22,
    name: "DECENTRALIZED FINANCE PLATFORM",
    description: "A comprehensive DeFi platform offering various financial services like lending, borrowing, and yield farming on blockchain networks.",
    image: portfolioImg2,
    features: [
      "Smart contract-based lending",
      "Liquidity pool management",
      "Yield optimization strategies",
      "Cross-chain asset transfers"
    ]
  }
];

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const ProjectCard = ({ project, index }) => {
  const isEven = index % 2 === 0;
  const animationDirection = isEven ? 'right' : 'left';

  return (
    <div className={`grid items-center gap-5 lg:grid-cols-2`}>
      <div className={`jos mx-3 relative z-11 order-2 md:order-2 lg:order-${isEven ? '2' : '1'} flex flex-col`} data-jos_animation={`fade-${animationDirection}`}>
        <div className="mb-3">
          <h2 id={`portfoliofrom${animationDirection}${index + 1}`} className="font-Syne text-4xl font-semibold">{project.title}</h2>
        </div>

        <p id={`portfoliofrom${animationDirection}${index + 1}`} className=" text-gray-700 mb-3 line-clamp-3">
          {project.title_description}
        </p>

        <div>
          <div className="mb-3">
            <p id={`portfoliofrom${animationDirection}${index + 1}`} className="text-md font-semibold">Key Features:</p>
          </div>
        </div>

        <ul className="mb-5 flex flex-col text-sm text-ColorBlack ps-5" id={`portfoliofrom${animationDirection}${index + 1}`}>
          {project.categories.Outcome.map((feature, index) => (
            <li key={index} className="flex items-center mb-1">
              <span className="mr-3 inline-block text-xl text-[#53818b]">
                <i className="fa fa-check"></i>
              </span>
              {feature.title}
            </li>
          ))}
        </ul>

        <div
          className="w-max rounded-lg border border-black  hover:text-[#52818B] hover:border-[#52818B] hover:shadow-xl"
          id={`portfoliofrom${animationDirection}${index + 1}`}>
          <Link to={`/portfolio/${project.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '')}/${project._id}`}
            className=" inline-flex items-center gap-x-2 text-base font-bold text-black p-3"
          >
            <span>
              Explore Case Study
            </span>
            <span className="transition-all duration-300 ease-in-out hover:translate-x-2">
              <i className="fa-solid fa-arrow-right"></i>
            </span>
          </Link>
        </div>


      </div>

      <div className={`jos relative z-11 order-1 md:order-1 lg:order-${isEven ? '1' : '2'}`} data-jos_animation={`fade-${isEven ? 'left' : 'right'}`}>
        <div className="overflow-hidden flex justify-center items-center  rounded-3xl">
          <img
            src={project.thumbnail_image}
            className='sm:w-[90%] min-h-[200px] sm:h-auto shadow-inner rounded-3xl border-2 border-black'
            height='100%'
            width='100%'
            alt={project.title}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

const Listofportfolio = () => {
  const [portfolioList, setportfolioList] = useState([])
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER_URL}portfolio/getportfoliolist`)
    .then((res) => {
        setportfolioList(res.data.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err)
      })
  }, [])


  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // projects.forEach((project, index) => {
    //   const isEven = index % 2 === 0;
    //   const direction = isEven ? 'right' : 'left';
    //   const oppositeDirection = isEven ? 'left' : 'right';

    //   gsap.set(`#portfoliofrom${direction}${index + 1}`, { y: 30, opacity: 0 });
    //   gsap.set(`#portfoliofrom${oppositeDirection}${index + 1}`, { y: -30, opacity: 0 });

    //   gsap.to(`#portfoliofrom${direction}${index + 1}`, {
    //     scrollTrigger: `#portfoliofrom${direction}${index + 1}`,
    //     y: 0,
    //     opacity: 1,
    //     start: "top center",
    //     duration: 1.5,
    //     delay: 0.2,
    //     ease: "power2.out",
    //   });

    //   gsap.to(`#portfoliofrom${oppositeDirection}${index + 1}`, {
    //     scrollTrigger: `#portfoliofrom${oppositeDirection}${index + 1}`,
    //     y: 0,
    //     opacity: 1,
    //     start: "top center",
    //     duration: 1.5,
    //     delay: 0.2,
    //     ease: "power2.out",
    //   });
    // });
  }, []);

  return (
    <>

      <style>
        {`

.clip-polygon {
  clip-path: polygon(54% 0%, 54% 14%, 100% 15%, 100% 100%, 0% 100%, 0% 0%);
}



`}
      </style>

      <section className="section-content">
        <ScrollToTop />
        <div className="bg-[#F7F7F7]">
          <div className="section-space relative z-[11]">
            <section className="relative bg-[#F7F7F7] py-10 md:py-20 overflow-hidden">
              <div className="container mx-auto px-6 md:px-12 lg:px-20">
                <div className="text-center max-w-3xl mx-auto mb-16">
                  <h2 className="font-Syne leading-[1.14] -tracking-[1px] text-4xl md:text-6xl font-semibold animate-fade-in">
                    Crafted <span className="text-[#52818B]">Masterpieces</span> That Speak Louder Than Words
                  </h2>
                  <p className="mt-6 text-lg md:text-xl opacity-80">
                    Dive into our collection of creative solutions, where each project reflects our passion, dedication, and skill in delivering top-notch results.
                  </p>
                </div>

              </div>
            </section>
            <div className="flex flex-col gap-y-20 lg:gap-y-20 xl:gap-y-20 pb-12">


              {loading ? (
                <div className="w-full mx-auto rounded-md bg-[#F7F7F7] p-4">
                  <div className="flex animate-pulse space-x-6">
                    <div className="flex h-80 w-[45%] items-center justify-center rounded-[20px] bg-slate-200 text-center text-black/20">Loading ...</div>
                    <div className="flex-1 space-y-4 py-2">
                      <div className="h-4 w-3/4 rounded bg-slate-200"></div>
                      <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                          <div className="col-span-2 h-4 rounded bg-slate-200"></div>
                          <div className="col-span-1 h-4 rounded bg-slate-200"></div>
                        </div>
                        <div className="h-4 rounded bg-slate-200"></div>
                        <div className="h-4 rounded bg-slate-200"></div>
                        <div className="grid grid-cols-3 gap-4">
                          <div className="col-span-1 h-4 rounded bg-slate-200"></div>
                          <div className="col-span-2 h-4 rounded bg-slate-200"></div>
                        </div>
                        <div className="h-4 rounded bg-slate-200"></div>
                        <div className="h-4 rounded bg-slate-200"></div>
                        <div className="grid grid-cols-3 gap-4">
                          <div className="col-span-2 h-4 rounded bg-slate-200"></div>
                          <div className="col-span-1 h-4 rounded bg-slate-200"></div>
                        </div>
                      </div>
                      <div className="h-12 w-[60%] transform rounded-md bg-slate-200 shadow-md transition duration-150 ease-in-out hover:scale-105"></div>
                    </div>
                  </div>
                </div>
              ) : (
                portfolioList.map((project, index) => (
                  <React.Fragment key={project._id}>
                    <ProjectCard project={project} index={index} key={project._id} />
                    {console.log(project)}
                    {index < portfolioList.length - 1 && <hr className='w-3/4 mx-auto p-0 m-0' />}
                  </React.Fragment>
                ))
              )}


            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Listofportfolio;