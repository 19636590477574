import React from 'react'
import { Helmet } from 'react-helmet'
import Languagesslider from '../components/about/Languagesslider.js'
import BlogsectionHome from '../components/blog/BlogsectionHome.js'
import Getintouch from '../components/contact/Getintouch.js'
import Awards from '../components/home/Awards'
import Feedback from '../components/home/Feedback'
import Hero from '../components/home/Hero'
import Services from '../components/home/Services'
import Whyushome from '../components/about/Whyushome'

const Home = () => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "CodeLamda",
        "description": "CodeLamda delivers cutting-edge IT solutions with expertise in serverless technologies, cloud development, DevOps, and AI.",
        "url": "https://www.codelamda.com",
        "logo": "https://www.codelamda.com/logo.png",
        "sameAs": [
            "https://www.facebook.com/codelamda",
            "https://www.twitter.com/codelamda",
            "https://www.linkedin.com/company/codelamda"
        ]
    };

    return (
        <div className="page-wrapper relative z-[1] bg-white">
            <Helmet>
                <title>CodeLamda | Innovative IT Solutions for Business Growth</title>
                <meta name="description" content="CodeLamda offers cutting-edge IT solutions including serverless technologies, cloud development, DevOps, and AI. Transform your business with our expert services." />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <main className="main-wrapper relative overflow-hidden">
                <Hero />
                <Services />
                <Languagesslider />
                <Awards/>
                <BlogsectionHome />
                <Feedback />
                <Whyushome />
                <Getintouch />
            </main>
        </div>
    )
}

export default Home