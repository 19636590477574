import HeaderTitle from "../components/common/HeaderTitle";
import About from "../components/about/About";
import Numbers from "../components/about/Numbers";
import Mission from "../components/about/Mission";

const AboutPage = () => {
  return (
    <>
      <HeaderTitle title="About" />
      <About />
      <Numbers />
      <Mission />
    </>
  )
}

export default AboutPage;