import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import abouticon1 from '../../assets/img/icons/about/aboutus_icon1.webp';
import abouticon2 from '../../assets/img/icons/about/aboutus_icon2.webp';
import abouticon3 from '../../assets/img/icons/about/aboutus_icon3.webp';
import abouticon4 from '../../assets/img/icons/about/aboutus_icon4.webp';
import abouticon5 from '../../assets/img/icons/about/aboutus_icon5.webp';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const About = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const animateElements = (selector, yStart, delay) => {
      gsap.set(selector, { y: yStart, opacity: 0 });
      gsap.to(selector, {
        scrollTrigger: selector,
        y: 0,
        opacity: 1,
        start: "top center",
        duration: 1.5,
        delay,
        ease: "power2.out",
      });
    };

    animateElements("#aboutusheading", 30, 0.2);
    animateElements(".benefit-card", 40, 0.3);
  }, []);

  const benefitCards = [
    {
      icon: abouticon4,
      title: "Uncompromising Quality Assurance",
      description: "Experience excellence with our unwavering commitment to quality. Our rigorous QA processes ensure top-tier software solutions that surpass industry standards and client expectations.",
      bgcolor: "#fff3e0",
      imagecolor: "invert(85%) sepia(50%) saturate(600%) hue-rotate(-10deg) brightness(95%) contrast(90%)"  // Neutral filter for clarity
    },
    {
      icon: abouticon5,
      title: "Swift and Reliable Delivery",
      description: "Time is money in the digital world. Our agile methodologies and efficient project management guarantee on-time delivery without sacrificing the superior quality of our custom software solutions.",
      bgcolor: "#e8f5e9",
      imagecolor: "invert(85%) sepia(0%) saturate(1000%) hue-rotate(90deg) brightness(90%) contrast(90%)" // Bright filter for vibrancy
    },
    {
      icon: abouticon3,
      title: "Client-Centric Approach",
      description: "Your success is our priority. We offer tailored software development services, addressing your unique business challenges and objectives to foster enduring partnerships and drive your digital growth.",
      bgcolor: "#e3f2fd",
      imagecolor: "invert(69%) sepia(18%) saturate(459%) hue-rotate(179deg) brightness(94%) contrast(92%)" // Filter for the image
    },
    {
      icon: abouticon2,
      title: "Collaborative Creativity",
      description: "Harness the power of our diverse, skilled team. We combine creative thinking with technical prowess to deliver groundbreaking software solutions, pushing the boundaries of what's possible in digital innovation.",
      bgcolor: "#f8d7da",
      imagecolor: "brightness(0) saturate(100%) invert(13%) sepia(85%) saturate(6758%) hue-rotate(347deg) brightness(86%) contrast(91%)"
    },
    {
      icon: abouticon1,
      title: "Passionate Innovation",
      description: "Fueled by passion for cutting-edge technology, we infuse creativity and expertise into every project. Our enthusiasm translates into innovative, high-performance software solutions that propel your business forward.",
      bgcolor: "#f9f2ff",
      imagecolor: "brightness(0) saturate(100%) invert(91%) sepia(66%) saturate(1297%) hue-rotate(223deg) brightness(91%) contrast(88%)"
    },
  ];

  return (
    <section className="section-features bg-[#F7F7F7] pb-8">
      <ScrollToTop />
      <div className="container mx-auto relative z-[11]">
        <div className="text-center mb-12 pt-12 ">
          <h2 className="font-Syne leading-[1.14] -tracking-[1px] font-semibold text-4xl text-gray-800" id='aboutusheading'>
            Benefits of Choosing <span className='text-[#53818B]'>CodeLamda</span> Technologies
          </h2>
          <p className="text-lg text-gray-600 mt-4 max-w-xl mx-auto" id='aboutusheading'>
            Experience growth like never before with our innovative approaches
          </p>
        </div>
        <div className="flex flex-wrap justify-center gap-6">
          {benefitCards.map((card, index) => (
            <div key={index} className=" w-full sm:w-[80%] md:w-[40%] lg:w-[30%] rounded-3xl transition duration-300 hover:shadow-lg">
              <div className={`benefit-card bg-white p-6 rounded-3xl text-center h-full flex flex-col`}>
                <div className={`mx-auto px-4 pt-2 rounded-full mb-6`} style={{ backgroundColor: card.bgcolor }} >
                  <img src={card.icon} alt={`${card.title} icon`} className="mb-3 h-12 w-12 transition-transform duration-300" style={{ filter: card.imagecolor }} />
                </div>
                <h3 className="text-xl font-semibold text-gray-800 mb-2">{card.title}</h3>
                <p className="flex-grow text-gray-600">{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;