import React from 'react';
import PropTypes from 'prop-types';
import headerImg1 from '../../assets/img/elements/breadcrumb-shape-1.svg';
import headerImg2 from '../../assets/img/elements/breadcrumb-shape-2.svg';

const HeaderTitle = React.memo(({ title, parent }) => {
    return (
        <section className="section-breadcrumb">
            <div className="breadcrumb-wrapper bg-[#F7F7F7]">
                <div className="container-default">
                    <div className="breadcrumb-block">
                        
                    </div>
                </div>
                <div
                    className="absolute left-0 top-0 -z-10 bg-no-repeat bg-contain"
                    style={{ backgroundImage: `url(${headerImg1})`, width: '291px', height: '380px' }}
                    aria-hidden="true"
                />
                <div
                    className="absolute bottom-0 right-0 -z-10 bg-no-repeat bg-contain"
                    style={{ backgroundImage: `url(${headerImg2})`, transform: "scaleY(-1)", width: '291px', height: '380px' }}
                    aria-hidden="true"
                />
            </div>
        </section>
    );
});

HeaderTitle.propTypes = {
    title: PropTypes.string.isRequired,
    parent: PropTypes.string,
};

export default HeaderTitle;