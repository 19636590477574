import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import React, { useEffect } from 'react'
import '../../assets/img/experimental/fonts/webfonts/syne/stylesheet.css'
import bgShape from "../../assets/img/experimental/home-9-hero-bg-shape.svg"
import certifficate_AWSdevOpsengineer from '../../assets/img/icons/certifficate_AWSdevOpsengineer1.webp'
import certificate_AWSDeveloper from '../../assets/img/icons/certificate_AWSDeveloper1.webp'
import certificate_AWSsolutionarchitect from '../../assets/img/icons/certificate_AWSsolutionarchitect1.webp'
import certificate_AWSsolutionarchitectassociate from '../../assets/img/icons/certificate_AWSsolutionarchitectassociate1.webp'
import certificate_AWSsysOpsadministrator from '../../assets/img/icons/certificate_AWSsysOpsadministrator1.webp'
import certificate_CloudPractitioner from '../../assets/img/icons/certificate_CloudPractitioner1.webp'

const Awards = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

   
    gsap.set("#heading", { y: 50, opacity: 0 });
    gsap.to("#heading", {
      scrollTrigger: "#heading",
      y: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.3,
      ease: "power2.out"
    });
    gsap.set("#fromright", { x: -50, opacity: 0 });
    gsap.to("#fromright", {
      scrollTrigger: "#fromright",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.3,
      ease: "power2.out"
    });

    gsap.set("#fromleft", { x: 50, opacity: 0 });
    gsap.to("#fromleft", {
      scrollTrigger: "#fromleft",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.3,
      ease: "power2.out"
    });
  }, [])

  const certifications = [
    {
      title: "AWS Cloud Practitioner",
      image: certificate_CloudPractitioner,
      description: "Foundational knowledge of AWS Cloud services, security, and architecture. Ideal for non-technical roles working with AWS."
    },
    {
      title: "AWS Developer",
      image: certificate_AWSDeveloper,
      description: "Expertise in developing, deploying, and debugging cloud-based applications using AWS services."
    },
    {
      title: "AWS SysOps Administrator",
      image: certificate_AWSsysOpsadministrator,
      description: "Proficient in deployment, management, and operations on AWS. Skilled in data flow control and cost optimization."
    },
    {
      title: "AWS Solutions Architect Professional",
      image: certificate_AWSsolutionarchitect,
      description: "Advanced knowledge in designing scalable and reliable distributed systems using AWS technologies."
    },
    {
      title: "AWS DevOps Engineer",
      image: certifficate_AWSdevOpsengineer,
      description: "Expert in implementing continuous delivery systems and automating security controls on AWS."
    },
    {
      title: "AWS Solutions Architect Associate",
      image: certificate_AWSsolutionarchitectassociate,
      description: "Capable of designing scalable, highly available systems on AWS, with focus on best practices and cost optimization."
    }
  ];

  return (
    <section className="section-service bg-[#F7F7F7]">
      <div className="horizontal-line bg-ColorBlack"></div>
      <div className="relative z-10 overflow-hidden mt-10 lg:pb-16">
        <div className="section-space">
          <div className="container-custom">
            <div className="jos mb-8 xl:mb-8">
              <div className="mx-auto max-w-[590px]">
                <h2 className="mb-5 text-center font-Syne text-4xl font-semibold leading-[1.14] -tracking-[1px] text-ColorOil lg:text-5xl xl:text-[56px]" id='heading'>
                  Certifications
                </h2>
              </div>
            </div>

            <div className="grid gap-4 sm:grid-cols-2 justify-items-center">
              {certifications.map((cert, index) => (
                <div key={index} id={index % 2 === 0 ? 'fromright' : 'fromleft'}>
                  <div className="group rounded-[10px] border border-[#E6E6E6] bg-white p-8 transition-all duration-300 ease-in-out hover:border-gray-300 hover:bg-transparent lg:p-10 h-full cursor-pointer">
                    <div className="flex flex-col gap-x-5 gap-y-1 sm:gap-y-3 md:gap-y-4 lg:flex-row" id='card4'>
                      <div className="relative mx-auto flex w-[160px] items-center justify-center sm:w-[150px] md:w-[160px] lg:w-[180px]" id='card1'>
                        <img src={cert.image} alt={`${cert.title} icon`} width="180" height="180" className="opacity-100 h-auto w-full transition-all duration-300 ease-in-out" />
                      </div>
                      <div className="flex-1 text-center lg:text-left">
                        <div className="mb-[15px] font-Syne text-2xl font-semibold -tracking-[0.9px] text-black" id='card1'>
                          {cert.title}
                        </div>
                        <p className="mb-5 text-ColorBlack/80" id='card1'>
                          {cert.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 right-0 -z-10">
          <img className='opacity-30 sm:opacity-50 lg:opacity-100' src={bgShape} alt="Background Shape" width="376" height="367" />
        </div>
      </div>
    </section>
  )
}

export default Awards