import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import Slider from "react-slick";

// Import client images
import c2 from "../../assets/img/feedbackDP2.webp";
import c3 from "../../assets/img/feedbackDP3.webp";
import c1 from "../../assets/img/feedbackDP1.webp";

const clientTestimonials = [
  {
    name: "Emily Rodriguez",
    position: "Product Manager, TechGrowth",
    image: c1,
    quote:
      "codeLamda's ability to translate complex requirements into functional solutions is impressive. They've significantly accelerated our product development.",
  },
  {
    name: "David Kim",
    position: "CIO, Global Innovations",
    image: c2,
    quote:
      "The cloud migration project led by codeLamda was seamless. Their expertise in AWS and Azure is truly world-class.",
  },
  {
    name: "Lisa Thompson",
    position: "Director of Engineering, DataFlow Systems",
    image: c3,
    quote:
      "codeLamda's DevOps solutions have revolutionized our deployment process. We've seen a 40% increase in efficiency.",
  },
];

const HomeFslider = () => {
  const settings = {
    infinite: true,
    slidesToShow:
      window.innerWidth > 1024 ? 3 : window.innerWidth > 640 ? 2 : 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2500,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set("#feedbackcard", { y: 70, opacity: 0 });

    gsap.to("#feedbackcard", {
      scrollTrigger: "#feedbackcard",
      y: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
  }, []);

  return (

    <>
      <style>
        {`
      
      `}
      </style>
      <Slider {...settings}>
        {clientTestimonials.map((client, index) => (
          <div
            key={index}
            className="group grid gap-y-10 md:grid-cols-2 lg:grid-cols-3 border-e"
          >
            <div
              className="min-h-[360px] h-[360px] max-h-[400px] relative flex flex-col rounded-3xl items-center gap-[30px] px-4 text-center xl:px-[30px] xl:after:block group-hover:bg-[#52818B] group-hover:rounded-3xl py-5 transition-all duration-300"
              style={{ minHeight: "100% !important" }}
              data-jos_animation="fade-left"
              data-jos_delay="0"
            >
              <img
                src={client.image}
                alt={`${client.name} profile`}
                className="h-[70px] w-auto rounded-full"
                id="feedbackcard"
              />
              <div id="feedbackcard">
                <div className="text-xl font-semibold capitalize leading-[1.33] -tracking-[0.5px] text-ColorBlack group-hover:text-white xl:text-2xl duration-300">
                  {client.name}
                </div>
                <div className="text-base font-medium text-ColorGray group-hover:text-white mb-4 duration-300">
                  {client.position}
                </div>
                <q className="group-hover:text-white duration-300">
                  {client.quote}
                </q>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default HomeFslider;
