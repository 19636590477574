import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SEOMetaTags from "./components/SEOMetaTags";
import BlogDetails from "./components/blog/BlogDetails";
import Blogs from "./components/blog/Blogs";
import CareerDetails from "./components/careerDetails";
import Navbar from "./components/common/Navbar";
import AboutPage from "./pages/AboutPage";
import Career from "./pages/Career";
import ContactPage from "./pages/ContactPage";
import Home from "./pages/Home";
import ServicePage from "./pages/ServicePage";
import PortfolioPage from "./pages/PortfolioPage";
import Projectdetails from "./pages/Projectdetails";

const App = () => {
  return (
    <Router>
      <SEOMetaTags
        title="Codelamda | Serverless & Cloud Experts | AWS, DevOps, AI Solutions"
        description="Codelamda delivers cutting-edge IT solutions with expertise in serverless technologies, cloud development, DevOps, and AI. Transform your business with our AWS-certified experts."
        keywords="serverless technologies, AWS, cloud development, DevOps, Node.js, full-stack development, blockchain, AI, Kubernetes, Docker, microservices, IoT, React Native, digital transformation"
        canonicalUrl="https://www.codelamda.com"
        ogImage="https://www.codelamda.com/logo.png"
      />
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="service" element={<ServicePage />} />
          <Route path="career" element={<Career />} />
          <Route path="careerDetails" element={<CareerDetails />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/portfolio/:portfolioname/:id" element={<Projectdetails />} />
          <Route path="/blog/:source/:id/:title" element={<BlogDetails />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;