import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import emailIcon from '../../assets/img/icons/contact/email_icon.webp';
import locationIcon from '../../assets/img/icons/contact/location_icon.webp';

const ContactForm = () => {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        
        const animateElement = (selector, yOffset) => {
            gsap.set(selector, { y: yOffset, opacity: 0 });
            gsap.to(selector, {
                scrollTrigger: selector,
                y: 0,
                opacity: 1,
                start: "top center",
                duration: 1.5,
                delay: 0.2,
                ease: "power2.out",
            });
        };

        animateElement("#heading", 30);
        animateElement("#formcard", 30);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <section className="bg-[#F7F7F7] py-10 pt-10" id="visitorcontact">
            <h1 className="text-[#53818B] mb-8 text-center font-Syne text-3xl font-semibold leading-[1.14] -tracking-[1px] lg:text-5xl relative z-10" id="heading">
                Visit or Chat with us
            </h1>

            <div className="flex flex-col lg:flex-row gap-6 md:mx-20">
                <div className="w-full lg:w-2/5 p-4 border-y-4 border-t-[#53818B] border-b-[#53818B] shadow-xl flex flex-col justify-between bg-white rounded-lg relative z-10" id="formcard">
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col h-full">
                            <address className="flex flex-col gap-6 text-center lg:flex-row lg:text-left my-3 not-italic">
                                <img
                                    src={locationIcon}
                                    alt="Location icon"
                                    className="bg-[#53818B] mx-auto size-12 p-2 lg:mx-0 rounded-full border-white"
                                    width="48"
                                    height="48"
                                />
                                <div>
                                    <h2 className="mb-2 text-xl font-semibold leading-[1.33] tracking-[-0.5px] xxl:text-2xl">
                                        Location:
                                    </h2>
                                    <p>
                                        704, APMC Market (Krushi Bazar),<br />
                                        Near Sahar Darwaja, Ring Road,<br />
                                        Surat, Gujarat, India.
                                    </p>
                                </div>
                            </address>

                            <div className="flex flex-col gap-6 text-center lg:flex-row lg:text-left my-3">
                                <img
                                    src={emailIcon}
                                    alt="Email icon"
                                    className="bg-[#53818B] mx-auto size-12 p-1 lg:mx-0 rounded-full border border-white"
                                    width="48"
                                    height="48"
                                />
                                <div>
                                    <h2 className="mb-2 text-xl font-semibold leading-[1.33] tracking-[-0.5px] xxl:text-2xl">
                                        Email:
                                    </h2>
                                    <Link to="mailto:contact@codelamda.com" className="hover:underline">contact@codelamda.com</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex-grow mt-6">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.8908159584093!2d72.84014567487934!3d21.1964958819971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f41a80d2781%3A0x804b71e95b8fc2bc!2sFameUX%20Technologies%20LLP!5e0!3m2!1sen!2sin!4v1724317820616!5m2!1sen!2sin"
                            className="w-full h-full border"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="CodeLamda Office Location"
                        ></iframe>
                    </div>
                </div>
                <div className="w-full lg:w-3/5 p-8 border-y-4 border-t-[#53818B] border-b-[#53818B] shadow-2xl bg-white rounded-lg" id="formcard">
                    <div className="container-default">
                        <div className="jos mx-auto max-w-[996px]">
                            <form onSubmit={handleSubmit} className="space-y-8">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                    <div>
                                        <label
                                            htmlFor="name"
                                            className="mb-2 block text-left font-semibold text-ColorBlack"
                                        >
                                            Your Name
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="John Doe"
                                            className="w-full rounded-lg border border-ColorBlack/50 px-4 py-3 outline-none transition-all duration-300 placeholder:text-ColorBlack/50 focus:border-ColorBlue focus:ring-2 focus:ring-ColorBlue"
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="mb-2 block text-left font-semibold text-ColorBlack"
                                        >
                                            Email Address
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="john.doe@example.com"
                                            className="w-full rounded-lg border border-ColorBlack/50 px-4 py-3 outline-none transition-all duration-300 placeholder:text-ColorBlack/50 focus:border-ColorBlue focus:ring-2 focus:ring-ColorBlue"
                                            required
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="subject"
                                        className="mb-2 block text-left font-semibold text-ColorBlack"
                                    >
                                        Subject
                                    </label>
                                    <input
                                        type="text"
                                        name="subject"
                                        id="subject"
                                        placeholder="Enter the subject of your message"
                                        className="w-full rounded-lg border border-ColorBlack/50 px-4 py-3 outline-none transition-all duration-300 placeholder:text-ColorBlack/50 focus:border-ColorBlue focus:ring-2 focus:ring-ColorBlue"
                                        required
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="message"
                                        className="mb-2 block text-left font-semibold text-ColorBlack"
                                    >
                                        Your Message
                                    </label>
                                    <textarea
                                        name="message"
                                        id="message"
                                        placeholder="Write your message here..."
                                        className="min-h-[150px] w-full rounded-lg border border-ColorBlack/50 px-4 py-3 outline-none transition-all duration-300 placeholder:text-ColorBlack/50 focus:border-ColorBlue focus:ring-2 focus:ring-ColorBlue"
                                        required
                                    ></textarea>
                                </div>
                                <button
                                    type="submit"
                                    aria-label="Submit"
                                    className="mt-6 px-8 py-3 bg-gradient-to-r from-[#53818B] to-[#436b74] text-white rounded-full hover:from-[#436b74] hover:to-[#53818B] transition-colors duration-300 shadow-lg transform hover:scale-105"
                                >
                                    Send Message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;