import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect, useRef } from 'react';

const Numbers = () => {
    const yearsRef = useRef(null);
    const developersRef = useRef(null);
    const projectsRef = useRef(null);
    const awardsRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.set("#aboutusheading", { y: 30, opacity: 0 });
        gsap.to("#aboutusheading", {
            scrollTrigger: "#aboutusheading",
            y: 0,
            opacity: 1,
            start: "top top",
            duration: 1.5,
            delay: 0.2,
            ease: "power2.out",
        });

        const counters = [
            { ref: yearsRef, value: 10 },
            { ref: developersRef, value: 30 },
            { ref: projectsRef, value: 200 },
            { ref: awardsRef, value: 10 },
        ];

        const animations = counters.map(counter =>
            gsap.fromTo(counter.ref.current,
                { innerText: 0 },
                {
                    innerText: counter.value,
                    duration: 2,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: counter.ref.current,
                        start: "top bottom",
                        toggleActions: "play none none none",
                    },
                    snap: { innerText: 1 },
                    onUpdate: function () {
                        if (counter.ref.current) {
                            counter.ref.current.innerText = `${Math.round(this.targets()[0].innerText)}+`;
                        }
                    }
                }
            )
        );
        return () => {
            animations.forEach(anim => anim.kill());
        };
    }, []);

    return (
        <>
            <div className="bg-[#F7F7F7] py-20">
                <div className="bg-white py-10 lg:rounded-br-[130px] lg:rounded-tl-[130px] border">
                    <div className="mx-auto max-w-7xl px-6 pb-8 lg:px-8">
                        <div className="mx-auto mb-16">
                            <h2 className="text-center text-5xl relative z-[10] font-Syne leading-[1.14] -tracking-[1px] font-semibold" id="aboutusheading">
                                Facts And Figures
                            </h2>
                        </div>
                        <dl className="grid grid-cols-2 gap-x-8 gap-y-16 text-center md:grid-cols-4 lg:grid-cols-4">
                            <div key={1} className="mx-auto flex max-w-xs flex-col gap-y-4">
                                <h5 className="leading-7 text-black/80 text-2xl"><span className="text-[#53818B]"> Years</span> of Experience</h5>
                                <h4 ref={yearsRef} className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                    0+
                                </h4>
                            </div>
                            <div key={2} className="mx-auto flex max-w-xs flex-col gap-y-4">
                                <h5 className="leading-7 text-black/80 text-2xl"><span className="text-[#53818B]">Specialized</span> Developers</h5>
                                <h4 ref={developersRef} className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                    0+
                                </h4>
                            </div>
                            <div key={3} className="mx-auto flex max-w-xs flex-col gap-y-4">
                                <h5 className="leading-7 text-black/80 text-2xl"><span className="text-[#53818B]">Success</span> Projects</h5>
                                <h4 ref={projectsRef} className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                    0+
                                </h4>
                            </div>
                            <div key={4} className="mx-auto flex max-w-xs flex-col gap-y-4">
                                <h5 className="leading-7 text-black/80 text-2xl"><span className="text-[#53818B]">Awards</span> Winning</h5>
                                <h4 ref={awardsRef} className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                    0+
                                </h4>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Numbers;