import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import fireicon from "../../assets/img/fire-icon.webp";
import getintouch from "../../assets/img/icons/getintouch.webp";

gsap.registerPlugin(ScrollTrigger);

const Getintouch = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set("#fromright", { x: -50, opacity: 0 });
    gsap.to("#fromright", {
      scrollTrigger: "#fromright",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 1.3,
      ease: "power2.out",
    });
    gsap.set("#fromleft", { x: 50, opacity: 0 });
    gsap.to("#fromleft", {
      scrollTrigger: "#fromleft",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 1.3,
      ease: "power2.out",
    });
  }, []);

  return (
    <>
      <div className="overflow-hidden bg-[#252525] py-5 text-3xl font-bold uppercase leading-[1.4] tracking-widest text-white relative">
        <Marquee speed={100}>
          <span className="inline-block mx-4">
            Crafting Success Through Digital Solutions
          </span>
          <img
            src={fireicon}
            alt="fire-icon"
            width="40"
            height="40"
            className="mx-4"
          />
          <span className="inline-block mx-4">
            Quality Work, Delivered with Precision
          </span>
          <img
            src={fireicon}
            alt="fire-icon"
            width="40"
            height="40"
            className="mx-4"
          />
          <span className="inline-block mx-4">
            WE COMPLETE OUR CLIENT'S PROJECTS EFFICIENTLY
          </span>
          <img
            src={fireicon}
            alt="fire-icon"
            width="40"
            height="40"
            className="mx-4"
          />
          <span className="inline-block mx-4">
            GOT A PROJECT IDEA? USE OUR FREE QUOTE NOW
          </span>
          <img
            src={fireicon}
            alt="fire-icon"
            width="40"
            height="40"
            className="mx-4"
          />
        </Marquee>
      </div>
      <section className="section-content">
        <div className="relative">
          <div className="section-space relative z-[11]">
            <div className="container-custom">
              <div className="flex flex-col gap-8 lg:mx-8 ms-4">
                <div className="grid items-center lg:grid-cols-2 xl:grid-cols-[1fr_minmax(0,_1.2fr)]">
                  <div
                    className="jos relative lg:order-2"
                    data-jos_animation="fade-right"
                  >
                    <div className="rounded-lg p-6 lg:p-8 xl:p-10">
                      <img
                        src={getintouch}
                        alt="Get in touch"
                        width="426"
                        height="398"
                        className="h-auto w-full scale-110 rounded-lg"
                        id="fromleft"
                      />
                    </div>
                  </div>
                  <div className="jos lg:order-1">
                    <div className="mb-4">
                      <h2
                        className="mb-4 font-Syne text-3xl font-semibold leading-tight -tracking-[0.5px] text-ColorOil lg:text-4xl xl:text-5xl"
                        id="fromright"
                      >
                        Connect with Us: Expert Solutions Await
                      </h2>
                    </div>
                    <p
                      className="flex-1 text-sm mb-3 text-gray-700"
                      id="fromright"
                    >
                      Ready to collaborate? We're excited to hear about your
                      project. Discover our contact methods and let's initiate a
                      discussion about your custom software development needs.
                      Whether you're looking for web development, mobile app
                      creation, or IT consulting, our expert team is here to
                      provide tailored digital solutions for your business.
                    </p>
                    <div className="mt-8" id="fromright">
                      <Link
                        to="/contact"
                        className="btn is-outline-black btn-animation is-medium group inline-block rounded-md transition-all duration-300 hover:bg-ColorOil hover:text-white"
                      >
                        <span>Contact us</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default React.memo(Getintouch);
