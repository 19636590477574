import React from 'react'
import Listofportfolio from '../components/Portfolio/Listofportfolio'

const PorfolioPage = () => {
    
    return (
       


        <div className="section-portfolio">
            <div className="section-space bg-[#F7F7F7]">
                <div className="container-default">

                    <Listofportfolio />

                </div>
            </div>
        </div>
    )
}

export default PorfolioPage
