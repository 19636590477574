import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import medium from "@giuseppecampanelli/medium-api";
import HeaderTitle from "../common/HeaderTitle";

const Combinedblogs = () => {
    const location = useLocation();
    const [blogs, setBlogs] = useState([]);
    const [isSearchShown, setIsSearchShown] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [visibleBlogs, setVisibleBlogs] = useState(6);
    const [hasMore, setHasMore] = useState(true);

    const getCurrentPage = useCallback(() => {
        const pathSegments = location.pathname.split('/');
        const currentPage = pathSegments[pathSegments.length - 1];
        if (currentPage === "blogs") {
            setIsSearchShown(true)
        }
    }, [location.pathname]);


    useEffect(() => {
        getCurrentPage();
        const fetchBlogs = async () => {
            try {
                // Fetch Medium blogs
                const mediumResponse = await medium.profile.getRecentPosts("ladumorrahul");

                // Format Medium blogs
                function getIdFromUrl(url) {
                    const urlParts = url.split('/');
                    return urlParts[urlParts.length - 1];
                }
                const extractImageSrc = (htmlString) => {
                    const imgSrcMatch = htmlString.match(/<img[^>]+src="([^">]+)"/);
                    return imgSrcMatch ? imgSrcMatch[1] : null;
                };

                const mediumBlogs = mediumResponse.map(item => ({
                    coverImage: extractImageSrc(item.description),
                    title: item.title,
                    description: item.description,
                    publicationDate: new Date(item.pubDate).toISOString(),
                    tags: item.categories,
                    blogid: getIdFromUrl(item.guid),
                    source: 'Medium',
                }));

                const combinedBlogs = [...mediumBlogs].sort((a, b) =>
                    new Date(b.publicationDate) - new Date(a.publicationDate)
                );

                setBlogs(combinedBlogs);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, [getCurrentPage]);

    const blogCategories = useMemo(() => {
        return blogs?.reduce((acc, blog) => {
            const categories = blog?.tags;

            if (categories) {
                categories.forEach(category => {
                    if (!acc[category]) {
                        acc[category] = 1;
                    } else {
                        acc[category]++;
                    }
                });
            }
            return acc;
        }, {});
    }, [blogs]);

    const handleTagClick = (tag) => {
        setSelectedTag(tag === selectedTag ? null : tag);
    };

    const filteredBlogs = useMemo(() => {
        let filtered = blogs;

        if (searchTerm.trim() !== "") {
            filtered = filtered.filter(blog =>
                blog.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (selectedTag && selectedTag !== "All") {
            filtered = filtered.filter(blog =>
                blog.tags && blog.tags.includes(selectedTag)
            );
        }

        setHasMore(filtered.length > visibleBlogs);

        return filtered.slice(0, visibleBlogs);
    }, [blogs, selectedTag, searchTerm, visibleBlogs]);

    const handleSearch = (e) => {
        e.preventDefault();
    };

    const loadMore = () => {
        setVisibleBlogs(prevVisible => prevVisible + 6);
    };


    const isVideoFormat = (url) => {
        const videoFormats = ['.mp4', '.webm', '.ogg', '.mov', '.avi', '.mkv', '.flv', '.gif'];
        return videoFormats.some(format => url.endsWith(format));
    };
    return (
        <>
            {isSearchShown && <HeaderTitle title="Blogs" />}
            <section className="section-blog">
                <div id="blog" className="section-space bg-[#F7F7F7]">
                    <div className="container-default relative z-[11]">
                        <div className="jos mb-[60px] xl:mb-20">
                            <div className="mx-auto max-w-[640px]">
                                <div className="mb-5">
                                    <h2 className="mb-5 text-center font-Syne text-[24px] font-semibold leading-[1.14] -tracking-[1px] sm:text-[25px] md:text-[28px] lg:text-[30px] xl:text-[40px]" id="blogheading">
                                        Browse our articles to learn more
                                    </h2>
                                </div>
                                <p className="text-center font-Syne" id="blogheading">
                                    A lot of information is given in our blogs. You can learn more
                                    about startup by reading our blogs. It's time to brainstorm
                                    ideas for your business
                                </p>
                            </div>
                        </div>

                        {isSearchShown &&
                            <aside className="jos flex flex-col ">
                                <div className="rounded-[10px] p-5 pb-0">
                                    <form onSubmit={handleSearch} className="relative h-[60px]">
                                        <input
                                            type="search"
                                            name="sidebar-search"
                                            id="sidebar-search"
                                            placeholder="Type to search..."
                                            className="focus:border-colortext-ColorBlue h-full w-full rounded-[50px] border border-[#E1E1E1] bg-white py-[15px] pl-16 pr-8 text-lg text-ColorBlack outline-none transition-all placeholder:text-ColorBlack"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            required
                                        />
                                        <button type="submit"
                                            className="absolute left-[30px] top-0 h-full hover:text-[#52818B]"
                                            aria-label="Search">
                                            <i className="fa-solid fa-magnifying-glass mr-2"></i>
                                        </button>
                                    </form>
                                </div>
                                <div className="rounded-[10px] p-8 pb-12">
                                    <div
                                        className="font-Syne relative mb-[30px] inline-block pb-[10px] text-xl font-semibold text-ColorBlack after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:bg-black">
                                        Tags
                                    </div>

                                    <ul className="flex flex-wrap gap-x-2 gap-y-4">
                                        <li key="all">
                                            <button
                                            aria-label="Search" 
                                                onClick={() => handleTagClick("All")}
                                                className={`font-Syne inline-block rounded-[55px] px-5 py-1 ${selectedTag === "All" || !selectedTag
                                                    ? 'bg-[#52818B] text-white'
                                                    : 'bg-ColorBlack bg-opacity-5 hover:bg-[#52818B] hover:text-white'
                                                    }`}
                                            >
                                                All
                                            </button>
                                        </li>
                                        {Object.keys(blogCategories).map(blog => (
                                            <li key={blog}>
                                                <button
                                                aria-label="Search" 
                                                    onClick={() => handleTagClick(blog)}
                                                    className={`font-Syne inline-block rounded-[55px] px-5 py-1 ${selectedTag === blog
                                                        ? 'bg-[#52818B] text-white'
                                                        : 'bg-ColorBlack bg-opacity-5 hover:bg-[#52818B] hover:text-white'
                                                        }`}
                                                >
                                                    {blog}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </aside>
                        }

                        <div
                            className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3"
                            id="blogcard"
                        >
                            {filteredBlogs &&
                                filteredBlogs.map((blog, index) => {

                                    let finalDescription = blog.description;

                                    if (typeof DOMParser !== 'undefined') {
                                        const parser = new DOMParser();
                                        const doc = parser.parseFromString(blog.description, 'text/html');
                                        const paragraphs = doc.querySelectorAll('p');

                                        if (paragraphs.length > 0) {
                                            finalDescription = paragraphs[0].textContent;
                                        } else {
                                            finalDescription = doc.body.textContent;
                                        }
                                    }

                                    return (
                                        <div className="my-3">
                                            <div className="relative flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl h-full">
                                                <div className="relative h-56 mx-4 -mt-6 overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
                                                    {isLoading && (
                                                        <div className="flex items-center justify-center w-full h-full bg-gray-200">
                                                            <span className="text-gray-500">Loading image...</span>
                                                        </div>
                                                    )}

                                                    {isVideoFormat(blog.coverImage) ? (
                                                        <video
                                                            width="100%"
                                                            height="100%"
                                                            autoPlay
                                                            loop
                                                            muted
                                                            onLoadedData={() => setIsLoading(false)}
                                                            onError={() => setIsLoading(false)}
                                                        >
                                                            {['mp4', 'webm', 'ogg', 'mov', 'avi', 'mkv', 'flv'].map(format => (
                                                                <source key={format} src={blog.coverImage.replace(/\.\w+$/, `.${format}`)} type={`video/${format}`} />
                                                            ))}
                                                            Video is not supported in your Browser.
                                                        </video>
                                                    ) : (
                                                        <img
                                                            src={blog.coverImage}
                                                            alt="card-image"
                                                            className={`w-full h-full object-cover ${isLoading ? 'hidden' : 'block'}`}
                                                            onLoad={() => setIsLoading(false)} // Set loading to false when the image loads
                                                            onError={() => setIsLoading(false)} // Optional: Handle error case
                                                        />
                                                    )}









                                                </div>
                                                <div className="p-6 flex flex-col flex-grow">
                                                    <h1 className="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                                                        {blog.title}
                                                    </h1>
                                                    <p className="block font-sans text-sm antialiased font-medium leading-relaxed text-gray-500 mb-5">
                                                        {new Date(blog.publicationDate).toLocaleDateString('en-US', {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric'
                                                        })}
                                                    </p>
                                                    <p className="block font-sans text-base antialiased font-light leading-relaxed text-inherit">
                                                        {finalDescription.split(" ").slice(0, 15).join(" ")}
                                                        {finalDescription.split(" ").length > 15 && "..."}
                                                    </p>
                                                </div>
                                                <div className="p-6 pl-0 pt-0 mt-auto">
                                                    <Link to={`/blog/${blog.source.toLowerCase()}/${blog.blogid}/${blog.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '')}`}>
                                                        <button
                                                            className="align-middle select-none font-sans font-bold text-center uppercase transition-all bg-[#52818B] text-white py-2 px-4 rounded-r-full shadow-md hover:bg-[#3a5d64] focus:outline-none focus:ring-2 focus:ring-[#52818B] focus:ring-opacity-50 flex items-center justify-between"
                                                            type="button"
                                                            aria-label={`Next`}>
                                                            Read More
                                                            <span style={{
                                                                position: 'absolute',
                                                                width: '1px',
                                                                height: '1px',
                                                                padding: 0,
                                                                margin: 0,
                                                                overflow: 'hidden',
                                                                clip: 'rect(0, 0, 0, 0)',
                                                                border: 0
                                                            }}>
                                                                {` ${blog.title}`} {/* Hidden title for SEO */}
                                                            </span>
                                                            <i className="fa-solid fa-arrow-right ml-2 bg-white text-[#52818B] p-2 rounded-full"></i>
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>

                        {hasMore && (
                            <div className="flex justify-center mt-16 mb-8" id="button">
                                <button
                                aria-label="Next" 
                                    onClick={loadMore}
                                    className="group relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white transition-all duration-300 ease-in-out bg-[#52818B] rounded-full overflow-hidden shadow-lg hover:shadow-xl"
                                >
                                    <span className="absolute inset-0 w-full h-full bg-[#52818B] opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"></span>
                                    <span className="relative z-10 flex items-center">
                                        Load More
                                    </span>
                                    <span className="absolute bottom-0 left-0 w-full h-1 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
                                </button>
                            </div>
                        )}

                    </div>
                </div>
            </section>
        </>
    )
}

export default Combinedblogs