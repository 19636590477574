import React, { useState, useEffect, useMemo } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { VscDebugBreakpointLog } from "react-icons/vsc";
import getquoteimage from '../assets/img/icons/getquoteimage.webp';
import angular from '../assets/img/icons/lan_slider_Angular1.webp';
import aws from '../assets/img/icons/lan_slider_AWS1.webp';
import mongodb from '../assets/img/icons/lan_slider_mongodb1.webp';
import nextjs from '../assets/img/icons/lan_slider_Nextjs1.webp';
import node from '../assets/img/icons/lan_slider_nodejs1.webp';
import azure from '../assets/img/icons/lan_slider_azure1.webp';
import firebase from '../assets/img/icons/lan_slider_firebase1.webp';
import graphql from '../assets/img/icons/lan_slider_graphql1.webp';
import postgres from '../assets/img/icons/lan_slider_postgre1.webp';
import react from '../assets/img/icons/lan_slider_react1.webp';
import typescript from '../assets/img/icons/lan_slider_typescript1.webp';
import vuejs from '../assets/img/icons/lan_slider_vuejs1.webp';
import jquery from '../assets/img/icons/lan_slider_jquery1.webp';
import lodash from '../assets/img/icons/lan_slider_lodash1.webp';
import d3js from '../assets/img/icons/lan_slider_d3js1.webp';
import bootstrap from '../assets/img/icons/lan_slider_bootstrap1.webp';
import tailwind from '../assets/img/icons/lan_slider_tailwindcss1.webp';
import svelte from '../assets/img/icons/lan_slider_svelte1.webp';
import python from '../assets/img/icons/lan_slider_python1.webp';
import java from '../assets/img/icons/lan_slider_java1.webp';
import php from '../assets/img/icons/lan_slider_php1.webp';
import express from '../assets/img/icons/lan_slider_express1.webp';
import django from '../assets/img/icons/lan_slider_django1.webp';
import springboot from '../assets/img/icons/lan_slider_springboot1.webp';
import mysql from '../assets/img/icons/lan_slider_mysql1.webp';
import sqlite from '../assets/img/icons/lan_slider_sqlite1.webp';
import googlecloud from '../assets/img/icons/lan_slider_googlecloud1.webp';
import digital_ocean from '../assets/img/icons/lan_slider_digital_ocean1.webp';
import docker from '../assets/img/icons/lan_slider_docker1.webp';
import rubyonrails from '../assets/img/icons/lan_slider_ruby.webp';
import go from '../assets/img/icons/lan_slider_go.webp';
import aspnet from '../assets/img/icons/lan_slider_asp.net.webp';
import rust from '../assets/img/icons/lan_slider_rust.webp';
import ember from '../assets/img/icons/lan_slider_ember.webp';
import pug from '../assets/img/icons/lan_slider_pug.webp';
import html from '../assets/img/icons/lan_slider_html.webp';
import css from '../assets/img/icons/lan_slider_css.webp';
import redis from '../assets/img/icons/lan_slider_redis.webp';
import mariadb from '../assets/img/icons/lan_slider_mariadb.webp';
import mssqlserver from '../assets/img/icons/lan_slider_microsoft_sql_server.webp';
import kubernetes from '../assets/img/icons/lan_slider_Kubernetes.webp';
import terraform from '../assets/img/icons/lan_slider_teraform.webp';
import ansible from '../assets/img/icons/lan_slider_ansible.webp';
import openshift from '../assets/img/icons/lan_slider_openshift.webp';
import github from '../assets/img/icons/lan_slider_github.webp';
import git from '../assets/img/icons/lan_slider_git.webp';
import bitbucket from '../assets/img/icons/lan_slider_bitbucket.webp';
import jenkins from '../assets/img/icons/lan_slider_jenkins.webp';
import travisci from '../assets/img/icons/lan_slider_travisCI.webp';
import postman from '../assets/img/icons/lan_slider_postman.webp';
import swagger from '../assets/img/icons/lan_slider_swagger.webp';
import figma from '../assets/img/icons/lan_slider_figma.webp';
import adobeXD from '../assets/img/icons/lan_slider_Adobexd.webp';
import vscode from '../assets/img/icons/lan_slider_vscode.webp';
import xcode from '../assets/img/icons/lan_slider_xcode.webp';


const Projectdetails = () => {
    const technologies = {
        Frontend: [
            { name: 'React', img: react },
            { name: 'Angular', img: angular },
            { name: 'Next.js', img: nextjs },
            { name: 'Vue.js', img: vuejs },
            { name: 'JQuery', img: jquery },
            { name: 'Lodash', img: lodash },
            { name: 'D3.js', img: d3js },
            { name: 'Bootstrap', img: bootstrap },
            { name: 'Tailwind', img: tailwind },
            { name: 'Svelte', img: svelte },
            { name: 'Ember.js', img: ember },
            { name: 'Pug', img: pug },
            { name: 'HTML5', img: html },
            { name: 'CSS3', img: css },
        ],
        Backend: [
            { name: 'Node.js', img: node },
            { name: 'GraphQL', img: graphql },
            { name: 'TypeScript', img: typescript },
            { name: 'Python', img: python },
            { name: 'Java', img: java },
            { name: 'PHP', img: php },
            { name: 'Express', img: express },
            { name: 'Django', img: django },
            { name: 'Spring Boot', img: springboot },
            { name: 'Ruby on Rails', img: rubyonrails },
            { name: 'Go', img: go },
            { name: 'ASP.NET', img: aspnet },
            { name: 'Rust', img: rust },
        ],
        Database: [
            { name: 'MongoDB', img: mongodb },
            { name: 'Firebase', img: firebase },
            { name: 'PostgreSQL', img: postgres },
            { name: 'MySQL', img: mysql },
            { name: 'SQLite', img: sqlite },
            { name: 'Redis', img: redis },
            { name: 'MariaDB', img: mariadb },
            { name: 'Microsoft SQL Server', img: mssqlserver },
        ],
        Infrastructure: [
            { name: 'Amazon Web Service', img: aws },
            { name: 'Microsoft Azure', img: azure },
            { name: 'Google Cloud', img: googlecloud },
            { name: 'Digital Ocean', img: digital_ocean },
            { name: 'Docker', img: docker },
            { name: 'Kubernetes', img: kubernetes },
            { name: 'Terraform', img: terraform },
            { name: 'Ansible', img: ansible },
            { name: 'OpenShift', img: openshift },
        ],
        Tools: [
            { name: 'Git', img: git }, // Version control system 
            { name: 'GitHub', img: github }, // Code hosting platform 
            { name: 'Bitbucket', img: bitbucket }, // Code hosting platform 
            { name: 'Jenkins', img: jenkins }, // Continuous integration tool 
            { name: 'Travis CI', img: travisci }, // Continuous integration service 
            { name: 'Postman', img: postman }, // API testing tool 
            { name: 'Swagger', img: swagger }, // API documentation tool 
            { name: 'Figma', img: figma }, // UI/UX design tool 
            { name: 'Adobe XD', img: adobeXD }, // UI/UX design tool 
            { name: 'VS Code', img: vscode }, // UI/UX design tool 
            { name: 'X Code', img: xcode }, // UI/UX design tool 
        ]
    };
    const ScrollToTop = () => {
        const { pathname } = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null;
    };

    const [selectedCategory, setSelectedCategory] = useState("Problems");
    const [Categories, setCategories] = useState({});
    const [selectedOption, setSelectedOption] = useState('Frontend');
    const [backendResponse, setBackendResponse] = useState([]);
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [portfoliodata, setportfoliodata] = useState([]);

    useEffect(() => {
        <ScrollToTop />
    }, [])

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}portfolio/getportfoliodetailes`, { portfolioid: id })
            .then((res) => {
                console.log("portfoliodata", res.data.data)
                setportfoliodata(res.data.data[0])
                setCategories(res.data.data[0].categories)
                setBackendResponse(res.data.data[0].technologies)
                console.log("categories:", res.data.data[0].categories)
                setLoading(false);
            }).catch((err) => {
                console.log(err)
            })
    }, [])


    // Filter technologies based on backend response
    const filteredTechnologies = useMemo(() => {
        const allTechnologies = Object.entries(technologies).reduce((acc, [key, techs]) => {
            acc[key] = techs.filter(tech => backendResponse.includes(tech.name));
            return acc;
        }, {});
        return allTechnologies;
    }, [backendResponse]);

    const memoizedTechnologies = useMemo(() => filteredTechnologies[selectedOption], [filteredTechnologies, selectedOption]);

    const hasTechnologies = memoizedTechnologies.length > 0;
    // technology used ends


    return (
        <>
            {loading ? (
                <div class="w-full mx-auto rounded-md bg-[#F7F7F7] p-4 pt-52 min-h-[90vh]">
                    <center>
                        <div class="flex animate-pulse space-x-6 max-w-7xl pb-20">
                            <div class="flex h-80 w-[45%] items-center justify-center rounded-[20px] bg-slate-200 text-center text-black/20">Loading ...</div>
                            <div class="flex-1 space-y-4 py-2">
                                <div class="h-4 w-3/4 rounded bg-slate-200"></div>
                                <div class="space-y-3">
                                    <div class="grid grid-cols-3 gap-4">
                                        <div class="col-span-2 h-4 rounded bg-slate-200"></div>
                                        <div class="col-span-1 h-4 rounded bg-slate-200"></div>
                                    </div>
                                    <div class="h-4 rounded bg-slate-200"></div>
                                    <div class="h-4 rounded bg-slate-200"></div>
                                    <div class="grid grid-cols-3 gap-4">
                                        <div class="col-span-1 h-4 rounded bg-slate-200"></div>
                                        <div class="col-span-2 h-4 rounded bg-slate-200"></div>
                                    </div>
                                    <div class="h-4 rounded bg-slate-200"></div>
                                    <div class="h-4 rounded bg-slate-200"></div>
                                    <div class="grid grid-cols-3 gap-4">
                                        <div class="col-span-2 h-4 rounded bg-slate-200"></div>
                                        <div class="col-span-1 h-4 rounded bg-slate-200"></div>
                                    </div>
                                </div>
                                <div class="h-12 w-[60%] transform rounded-md bg-slate-200 shadow-md transition duration-150 ease-in-out hover:scale-105"></div>
                            </div>
                        </div>
                        <div class="flex animate-pulse space-x-6 max-w-7xl pb-20">
                            <div class="flex-1 space-y-4 py-2">
                                <div class="h-4 w-3/4 rounded bg-slate-200"></div>
                                <div class="space-y-3">
                                    <div class="grid grid-cols-3 gap-4">
                                        <div class="col-span-2 h-4 rounded bg-slate-200"></div>
                                        <div class="col-span-1 h-4 rounded bg-slate-200"></div>
                                    </div>
                                    <div class="h-4 rounded bg-slate-200"></div>
                                    <div class="h-4 rounded bg-slate-200"></div>
                                    <div class="grid grid-cols-3 gap-4">
                                        <div class="col-span-1 h-4 rounded bg-slate-200"></div>
                                        <div class="col-span-2 h-4 rounded bg-slate-200"></div>
                                    </div>
                                    <div class="h-4 rounded bg-slate-200"></div>
                                    <div class="h-4 rounded bg-slate-200"></div>
                                    <div class="grid grid-cols-3 gap-4">
                                        <div class="col-span-2 h-4 rounded bg-slate-200"></div>
                                        <div class="col-span-1 h-4 rounded bg-slate-200"></div>
                                    </div>
                                </div>
                                <div class="h-12 w-[60%] transform rounded-md bg-slate-200 shadow-md transition duration-150 ease-in-out hover:scale-105"></div>
                            </div>
                            <div class="flex h-80 w-[45%] items-center justify-center rounded-[20px] bg-slate-200 text-center text-black/20">Loading ...</div>
                        </div>
                    </center>
                </div>
            ) : (
                <>

                    {/* Hero Section */}
                    <div class="bg-[#F7F7F7] py-12 pt-24 sm:py-32 sm:pb-24 p-5">
                        <div className="mx-auto max-w-7xl lg:px-0 flex flex-col lg:flex-row items-center">
                            {/* section left */}
                            <div class="w-[60%] max-md:w-full">
                                <div class="mb-8">
                                    <h1 class="m-0 font-black text-[52px] max-md:text-[32px] mb-5 font-brockmann">{portfoliodata.title}</h1>
                                    <p class="text-[18px] font-medium max-w-[700px]">{portfoliodata.title_description}</p>
                                </div>
                                <Link to='/contact'><button class="border shadow-[4px_4px_0px_0px_#52818B] text-secondary font-semibold rounded-md border-solid border-secondary transition-all duration-500 text-[12px] md:text-[16px] bg-white px-8 py-4" aria-label="Next">Know More</button></Link>
                            </div>
                            {/* section right */}
                            <div className="lg:w-2/5 mt-8 lg:mt-0 flex justify-center space-x-8">
                                <div className="bg-white p-4 rounded-3xl shadow-[4px_4px_0px_0px_#52818B] border-2 border-[#52818B]">
                                    <div className="flex flex-col space-y-4">
                                        <div class="py-1">
                                            <p class="text-[18px] font-medium tracking-[0.36px] mb-2">Platform</p>
                                            <p class="text-[24px] font-bold tracking-[0.48px] max-md:text-[20px]">{portfoliodata.platform.join(', ')}</p>
                                        </div>
                                        <div class="py-1">
                                            <p class="text-[18px] font-medium tracking-[0.36px] mb-2">Industry</p>
                                            <p class="text-[24px] font-bold tracking-[0.48px] max-md:text-[20px]">{portfoliodata.industry}</p>
                                        </div>
                                        <div class="py-1">
                                            <p class="text-[18px] font-medium tracking-[0.36px] mb-2">Location</p>
                                            <p class="text-[24px] font-bold tracking-[0.48px] max-md:text-[20px]">{portfoliodata.location}</p>
                                        </div>
                                        <div class="py-1">
                                            <p class="text-[18px] font-medium tracking-[0.36px] mb-2">Services</p>
                                            <p class="text-[24px] font-bold tracking-[0.48px] max-md:text-[20px]">{portfoliodata.services.join(', ')}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    {/* about project */}
                    <div className='bg-[#F7F7F7] py-12 sm:pt-24 sm:pb-24 p-5'>
                        <div className='max-w-7xl mx-auto'>
                            <div className="container text-secondary px-6 md:px-0 flex flex-col gap-6">
                                <h2 className="font-Syne font-semibold text-5xl">what is Project About ?</h2>
                                <p className="leading-8 text-lg">{portfoliodata.project_about}</p>

                            </div>
                        </div>
                    </div>


                    {/* information box dynamic */}
                    <div className='bg-[#F7F7F7] py-12 sm:pt-24 sm:pb-24 p-5'>
                        <div className='max-w-7xl mx-auto'>
                            <div className="info-box flex flex-col sm:flex-row bg-[#F7F7F7] rounded-lg">
                                <div className="text-white flex flex-wrap sm:flex-col sm:justify-normal justify-center items-center bg-[#52818B] p-5 sm:py-11 rounded-t-[15px] sm:rounded-tl-[15px] sm:rounded-bl-[15px] sm:rounded-tr-none sm:rounded-br-none w-full sm:w-[25%] shadow-[4px_4px_0px_0px_#52818B]">
                                    {Object.keys(Categories).map((category) => (
                                        <button
                                            key={category}
                                            onClick={() => { setSelectedCategory(category); }}
                                            aria-label="Next"
                                            className={`bg-transparent border-none p-2 my-1 cursor-pointer rounded-[15px] transition-colors duration-300 w-min sm:w-full text-center hover:bg-black/50 font-Syne font-semibold leading-9 text-[20px] ${selectedCategory === category ? 'active' : ''}`}
                                            style={{ backgroundColor: selectedCategory === category ? 'black' : 'transparent' }}
                                        >
                                            {category}
                                        </button>
                                    ))}
                                </div>
                                <div className="flex-1 bg-white p-5 sm:p-11 border border-[#52818B] rounded-b-[15px] sm:rounded-tr-[15px] sm:rounded-br-[15px] sm:rounded-tl-none sm:rounded-bl-none shadow-[4px_4px_0px_0px_#52818B]">
                                    {selectedCategory && (
                                        <div>
                                            <ul className='list-outside'>
                                                {Categories[selectedCategory].map((item, index) => (
                                                    <li key={index} className='mb-4'>
                                                        <div className="flex items-center">
                                                            <VscDebugBreakpointLog />
                                                            <h3 className="ml-2 text-[14px] md:text-lg font-semibold">{item.title}</h3>
                                                        </div>
                                                        <p className="pl-7 ">{item.description}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* image showcase */}
                    {portfoliodata.image_links && portfoliodata.image_links.length > 0 && (
                        <div className='bg-[#F7F7F7] py-12 sm:pt-16 sm:pb-24 px-5'>
                            <h2 className="mb-12 text-center font-Syne text-4xl font-semibold leading-[1.14] -tracking-[1px] text-ColorOil lg:text-5xl xl:text-[50px]" id='title'>Image Showcase</h2>

                            <div className='mx-auto max-w-7xl'>
                                <div class="columns-1 gap-7 md:columns-2 xl:columns-3">
                                    {
                                        portfoliodata.image_links.map((image, index) => {
                                            return (
                                                <div class="mb-8 break-inside-avoid" key={index}>
                                                    <img class="h-auto max-w-full rounded-xl border border-black shadow-xl" height="" width="" src={image} alt="Gallery image" />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )}


                    {/* technology used */}
                    <section className="section-service bg-[#F7F7F7]">
                        <div className="relative z-10">
                            <div className="section-space">
                                <div className="container-default">
                                    <div className='pt-10 pb-10'>
                                        {hasTechnologies && (
                                            <h2 className="mb-12 text-center font-Syne text-4xl font-semibold leading-[1.14] -tracking-[1px] text-ColorOil lg:text-5xl xl:text-[50px]" id='title'>
                                                Tools &Technologies We Used
                                            </h2>
                                        )}
                                        <div className='flex justify-center bg-transparent overflow-auto px-2'>
                                            <div className="jos mb-10 flex flex-wrap justify-center gap-x-6 lg:mb-[60px]">
                                                {Object.keys(filteredTechnologies).filter(option => filteredTechnologies[option].length > 0).map(option => (
                                                    <button
                                                        key={option}
                                                        className={`process-tab tab-button ${selectedOption === option ? 'active' : ''}`}
                                                        onClick={() => setSelectedOption(option)}
                                                        aria-label="Next"
                                                    >
                                                        <span id='option'>{option}</span>
                                                    </button>
                                                ))}
                                            </div>
                                        </div>

                                        <div className='flex flex-wrap justify-center gap-2 sm:gap-4'>
                                            {memoizedTechnologies.map((tech, index) => (
                                                <div
                                                    key={index}
                                                    className='w-40 h-40 sm:w-48 sm:h-48 shadow-xl rounded-2xl hover:shadow-md transition ease-in-out duration-100 cursor-default flex items-center justify-center'
                                                    id='itemlogo'
                                                >
                                                    <img src={tech.img} alt={tech.name} className='w-24 h-auto object-contain' loading="lazy" width="96" height="96" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* get free quote */}
                    <div class="bg-[#F7F7F7] py-12 sm:pt-12 sm:pb-0 p-5">
                        <div class="mx-auto max-w-7xl py-24 sm:py-32">
                            <div class="relative isolate overflow-hidden bg-[#252525] px-6 pt-16 shadow-2xl rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                                <svg
                                    viewBox="0 0 1024 1024"
                                    class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                                    aria-hidden="true"
                                >
                                    <defs>
                                        <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                            <stop stop-color="#52818B" />
                                            <stop offset="1" stop-color="#252525" />
                                        </radialGradient>
                                    </defs>
                                </svg>
                                <div class="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                                    <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                        Have an idea or want to build custom software?<br />
                                        Get a free quote today!
                                    </h2>
                                    <p class="mt-6 text-lg leading-8 text-gray-300">
                                        Our experienced team is here to bring your vision to life. Whether it's a new idea or a custom solution you need, we’re ready to help. Reach out to us and let's create something amazing together.
                                    </p>
                                    <div class="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                                        <Link to='/contact' class="rounded-md bg-[#52818B] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#416f75] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                                            Get a Free Quote
                                        </Link>
                                        <Link to='/service' class="text-sm font-semibold leading-6 text-white">
                                            Learn more <span aria-hidden="true">→</span>
                                        </Link>
                                    </div>
                                </div>
                                <div class="relative mt-16 lg:mt-8 h-80 ">
                                    <img
                                        class="absolute top-0 sm:left-0 sm:top-0 w-[57rem] max-w-none rounded-md rounded-tl-3xl"
                                        src={getquoteimage}
                                        // src="https://cdn.dribbble.com/userupload/11488285/file/original-262dc11025c018db4dd4b51d348f7aa2.png?resize=1024x768"
                                        alt="Custom software development screenshot"
                                        width="1824"
                                        height="1080"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Projectdetails