import HeaderTitle from "../components/common/HeaderTitle";
import ContactForm from "../components/contact/ContactForm";

const ContactPage = () => {
  return(
    <>
    <HeaderTitle title="Contact" /> 
    <ContactForm/>
    </>
  )
} 

export default ContactPage;