import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import medium from "@giuseppecampanelli/medium-api";
import HeaderTitle from "../common/HeaderTitle";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const BlogsectionHome = () => {
  const location = useLocation();
  const [blogData, setBlogData] = useState([]);
  const [isSearchShown, setIsSearchShown] = useState(false);

  const getCurrentPage = useCallback(() => {
    const pathSegments = location.pathname.split("/");
    const currentPage = pathSegments[pathSegments.length - 1];
    setIsSearchShown(currentPage === "blogs");
  }, [location.pathname]);

  useEffect(() => {
    getCurrentPage();
    const fetchBlogData = async () => {
      try {
        const posts = await medium.profile.getRecentPosts("ladumorrahul");
        setBlogData(posts);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };
    fetchBlogData();
  }, [getCurrentPage]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const animateElement = (selector, yOffset, delay) => {
      gsap.set(selector, { y: yOffset, opacity: 0 });
      gsap.to(selector, {
        scrollTrigger: selector,
        y: 0,
        opacity: 1,
        start: "top center",
        duration: 1.5,
        delay,
        ease: "power2.out",
      });
    };

    animateElement("#blogheading", 50, 0.2);
    animateElement("#blogcard", 100, 0.2);
    animateElement("#button", 50, 0.2);
  }, []);

  return (
    <>
      {isSearchShown && <HeaderTitle title="Blogs" />}
      <section className="section-blog bg-[#252525]">
        <div className="section-space">
          <div className="container-default">
            <div className="jos mb-[60px] xl:mb-20">
              <div className="mx-auto max-w-[640px]">
                <div className="mb-5">
                  <h2
                    className="mb-5 text-center font-Syne text-4xl font-semibold leading-[1.14] -tracking-[1px] text-[#F7F7F7] lg:text-5xl xl:text-[56px]"
                    id="blogheading"
                  >
                    Dev Diaries
                  </h2>
                </div>
                <p className="text-center text-[#F7F7F7]" id="blogheading">
                  A lot of information is given in our blogs. You can learn more
                  about startup by reading our blogs. It's time to brainstorm
                  ideas for your business
                </p>
              </div>
            </div>
            <div
              className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 pb-12"
              id="blogcard"
            >
              {blogData &&
                blogData.slice(0, 6)?.map((blog, index) => {
                  const blogId = blog.guid.split("/").pop();
                  const tempdom = document.createElement("div");
                  tempdom.innerHTML = blog.description;
                  const firstImgTag = tempdom.querySelector("img");
                  const srcLink = firstImgTag?.getAttribute("src");
                  const description = tempdom.querySelector("p")?.textContent;

                  return (
                    <div key={blogId} className="group h-full">
                      <div className="bg-[#1E1E1E] rounded-lg overflow-hidden shadow-md transition-all duration-300 hover:shadow-xl hover:shadow-[#52818B]/30 flex flex-col h-full">
                        <div className="relative h-56 overflow-hidden flex-shrink-0">
                          <img
                            src={srcLink}
                            alt={`blog-img-${index + 1}`}
                            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                          />
                          <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                          <div className="absolute bottom-0 left-0 right-0 p-4 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">
                            <span className="inline-block bg-[#52818B] text-white text-xs font-semibold px-2 py-1 rounded-full mb-2">
                              {blog.categories[0]}
                            </span>
                            <p className="text-white text-sm flex items-center">
                              <svg
                                className="w-4 h-4 mr-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                ></path>
                              </svg>
                              {blog.pubDate.split(" ")[0]}
                            </p>
                          </div>
                        </div>
                        <div className="p-6 flex flex-col flex-grow">
                          <h3 className="text-xl font-bold text-white mb-3 line-clamp-2 group-hover:text-[#52818B] transition-colors duration-300">
                            {blog.title}
                          </h3>
                          <p className="text-[#D1D1D1] text-sm mb-4 line-clamp-3 flex-grow">
                            {description}
                          </p>
                          <a
                            href={blog.guid}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center text-[#52818B] font-semibold hover:text-white transition-colors duration-300 mt-auto"
                          >
                            Read more
                            <svg
                              className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform duration-300"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="flex justify-center mt-16 mb-8" id="button">
              <Link
                to="/blogs"
                className="group relative inline-flex items-center justify-center px-8 py-4 text-lg font-bold text-white transition-all duration-300 ease-in-out bg-gradient-to-r from-[#52818B] to-[#3A5D64] rounded-full overflow-hidden shadow-lg hover:shadow-xl"
              >
                <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-[#3A5D64] to-[#52818B] opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"></span>
                <span className="relative z-10 flex items-center">
                  Explore More Insights
                  <svg
                    className="w-6 h-6 ml-2 transform group-hover:translate-x-1 transition-transform duration-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 7l5 5m0 0l-5 5m5-5H6"
                    ></path>
                  </svg>
                </span>
                <span className="absolute bottom-0 left-0 w-full h-1 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogsectionHome;
