import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Appicon from "../assets/img/Service_Appdevelopment.webp";
import devops from "../assets/img/Service_devops.webp";
import webicon from "../assets/img/Service_web_dev1.webp";
import clouddev from "../assets/img/Service_clouddev.webp";
import saasdev from "../assets/img/service_saasdev.webp";
import migration from "../assets/img/Service_migration.webp";
import optimization from "../assets/img/Service_optimization.webp";
import serverless from "../assets/img/Service_serverless.webp";

import HeaderTitle from "../components/common/HeaderTitle";

const ServicePage = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const animateContent = (id, xValue) => {
      gsap.set(id, { x: xValue, opacity: 0 });
      gsap.to(id, {
        scrollTrigger: id,
        x: 0,
        opacity: 1,
        start: "top center",
        duration: 1.5,
        delay: 0.2,
        ease: "power2.out",
      });
    };

    gsap.set("#serviceimage", { y: 10, opacity: 1 });
    gsap.to("#serviceimage", {
      scrollTrigger: "#serviceimage",
      y: 0,
      opacity: 1,
      start: "top center",
      duration: 0.9,
      delay: 0.2,
      ease: "none",
      yoyo: true,
      repeat: -1,
    });

    for (let i = 1; i <= 8; i++) {
      animateContent(`#servicecontentfromright${i}`, 10);
      animateContent(`#servicecontentfromleft${i}`, -10);
    }
  }, []);

  const services = [
    {
      icon: webicon,
      title: "Web Development",
      description: "Our expert web development services encompass responsive design, custom functionality, and SEO optimization. We create user-friendly, high-performance websites using the latest technologies like React, Angular, and Vue.js to ensure your online presence stands out and drives business growth.",
      offerings: [
        "Responsive Web Design",
        "Custom Web Applications",
        "E-commerce Solutions",
        "Content Management Systems (CMS)",
        "Progressive Web Apps (PWAs)",
        "API Development and Integration"
      ]
    },
    {
      icon: Appicon,
      title: "Mobile App Development",
      description: "Transform your business with our expert mobile app development. We create powerful, user-friendly apps for iOS and Android, optimized for app store visibility. Our solutions combine cutting-edge technology with intuitive design to boost user engagement and drive your digital success.",
      offerings: [
        "Native iOS App Development",
        "Native Android App Development",
        "Cross-platform App Development",
        "UI/UX Design for Mobile Apps",
        "App Store Optimization",
        "Mobile App Maintenance and Support"
      ]
    },
    {
      icon: devops,
      title: "DevOps Solutions",
      description: "Accelerate your software delivery with our comprehensive DevOps solutions. We implement CI/CD pipelines, automate infrastructure, and integrate monitoring tools to enhance collaboration and system reliability. Our expertise ensures seamless scalability and efficient resource utilization.",
      offerings: [
        "CI/CD Pipeline Implementation",
        "Infrastructure as Code (IaC)",
        "Containerization and Orchestration",
        "Monitoring and Logging Solutions",
        "Automated Testing and Deployment",
        "DevSecOps Integration"
      ]
    },
    {
      icon: clouddev,
      title: "Cloud Development",
      description: "Empower your business with our expert cloud development services. We create scalable, secure solutions using AWS, Azure, and Google Cloud, optimizing performance and cost-efficiency for your digital transformation journey.",
      offerings: [
        "Cloud-native Application Development",
        "Microservices Architecture",
        "Serverless Computing Solutions",
        "Cloud Migration Strategies",
        "Multi-cloud and Hybrid Cloud Solutions",
        "Cloud Security and Compliance"
      ]
    },
    {
      icon: saasdev,
      title: "Cloud-Native SaaS Applications",
      description: "We have a team of skilled developers who are experts in the latest app frameworks, like React, React Native, Node.js, Amazon Web Services, Docker, Kubernetes etc. We can help you build scalable applications that can grow with your business.",
      offerings: [
        "SaaS Platform Development",
        "Microservices-based SaaS Architecture",
        "Multi-tenant SaaS Solutions",
        "SaaS Integration and API Development",
        "Scalable Database Design for SaaS",
        "SaaS Analytics and Reporting"
      ]
    },
    {
      icon: migration,
      title: "Cloud Migration and Optimization",
      description: "Cloud is the biggest enabler of digital transformation. We help you adopt cloud technologies to accelerate your business. We are also authorized AWS Consulting Partner. Get in touch to know more.",
      offerings: [
        "Cloud Readiness Assessment",
        "Legacy System Migration to Cloud",
        "Cloud-to-Cloud Migration",
        "Data Migration and Synchronization",
        "Application Refactoring for Cloud",
        "Post-migration Support and Optimization"
      ]
    },
    {
      icon: optimization,
      title: "Cloud Infrastructure & Cost Optimization",
      description: "We help you assess, analyze and optimize your cloud infrastructure to reduce costs and improve performance. Embrace Infrastructure as Code to ensure consistency, scalability, and efficiency across your entire technology stack.",
      offerings: [
        "Cloud Cost Analysis and Forecasting",
        "Resource Rightsizing and Optimization",
        "Reserved Instance Planning",
        "Automated Cost Allocation and Tagging",
        "Continuous Monitoring for Cost Efficiency",
        "FinOps Implementation"
      ]
    },
    {
      icon: serverless,
      title: "Serverless Technologies",
      description: "Leverage our expertise in serverless technologies for rapid development and deployment. We minimize infrastructure management while maximizing cost efficiency, enabling you to focus on core business logic.",
      offerings: [
        "Serverless Application Architecture",
        "Function-as-a-Service (FaaS) Development",
        "Event-driven Serverless Solutions",
        "Serverless API Development",
        "Serverless Database Integration",
        "Serverless Security and Compliance"
      ]
    },
  ];

  return (
    <>
      <HeaderTitle title="Services" />
      <section className="section-content">
        <div className="bg-[#F7F7F7] relative">
          <div className="section-space relative z-[11]">
            <div className="container-custom">
              <div className="flex flex-col gap-10 lg:mx-10 ms-5">
                {services.map((service, index) => (
                  <div key={index} className="grid items-center lg:grid-cols-2 xl:grid-cols-[1fr_minmax(0,_1.2fr)]">
                    <div className={`jos relative lg:order-${index % 2 === 0 ? '1' : '2'}`}>
                      <div className="rounded-[10px] p-[30px] lg:p-10 xl:p-[50px]">
                        <img
                          src={service.icon}
                          alt={`${service.title} icon`}
                          width="426"
                          height="398"
                          className="h-auto w-full scale-110 rounded-[10px]"
                          id={`servicecontentfrom${index % 2 === 0 ? 'left' : 'right'}${index + 1}`}
                        />
                      </div>
                    </div>
                    <div
                      className={`jos lg:order-${index % 2 === 0 ? '2' : '1'}`}
                      data-jos_animation={index % 2 === 0 ? "fade-left" : "fade-right"}
                    >
                      <div>
                        <div className="mb-1">
                          <h2
                            className="mb-5 font-Syne text-3xl font-semibold leading-[1.14] -tracking-[1px] text-ColorOil lg:text-5xl xl:text-[56px]"
                            id={`servicecontentfrom${index % 2 === 0 ? 'right' : 'left'}${index + 1}`}
                          >
                            {service.title}
                          </h2>
                        </div>
                      </div>
                      <p className="text-lg mb-3" id={`servicecontentfrom${index % 2 === 0 ? 'right' : 'left'}${index + 1}`}>
                        {service.description}
                      </p>
                      <div>
                        <div className="mb-3">
                          <h5 id={`servicecontentfrom${index % 2 === 0 ? 'right' : 'left'}${index + 1}`}>
                            Services we Offer:
                          </h5>
                        </div>
                      </div>
                      <ul
                        className="flex flex-col text-sm text-ColorBlack ps-5"
                        id={`servicecontentfrom${index % 2 === 0 ? 'right' : 'left'}${index + 1}`}
                      >
                        {service.offerings.map((offering, offerIndex) => (
                          <li key={offerIndex}>
                            <span className="mr-3 inline-block text-xl text-[#53818b]">
                              <i className="fa fa-check"></i>
                            </span>
                            {offering}
                          </li>
                        ))}
                      </ul>

                      <div
                        className="mt-10 w-max rounded-lg border border-black  hover:text-[#52818B] hover:border-[#52818B] hover:shadow-xl"
                        id={`servicecontentfrom${index % 2 === 0 ? 'right' : 'left'}${index + 1}`}>
                        <Link
                          to={"/contact"}
                          className=" inline-flex items-center gap-x-2 text-base font-bold text-black p-3"
                        >
                          <span>
                            Book Free Appointment
                          </span>
                          <span className="transition-all duration-300 ease-in-out hover:translate-x-2">
                            <i className="fa-solid fa-arrow-right"></i>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicePage;