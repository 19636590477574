import React, { useEffect } from "react";
import icon1Black from "../../assets/img/icons/services/service_app1.webp";
import icon2Black from "../../assets/img/icons/icon-black-service-2.svg";
import icon4Black from "../../assets/img/icons/services/service_cloud1.webp";
import icon7Black from "../../assets/img/icons/services/service_costcutting1.webp";
import icon3Black from "../../assets/img/icons/services/service_devops1.webp";
import icon6Black from "../../assets/img/icons/services/service_optimization1.webp";
import icon5Black from "../../assets/img/icons/services/service_saas1.webp";
import icon8Black from "../../assets/img/icons/services/service_serverless1.webp";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

const Services = () => {
  //animation starts
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.set("#heading", { y: 30, opacity: 0 });
    gsap.set("#card1", { x: -50, opacity: 0 });
    gsap.set("#card2", { x: -50, opacity: 0 });
    gsap.set("#card3", { x: 50, opacity: 0 });
    gsap.set("#card4", { x: 50, opacity: 0 });
    gsap.set("#card5", { x: -50, opacity: 0 });
    gsap.set("#card6", { x: 50, opacity: 0 });
    gsap.set("#card7", { x: -50, opacity: 0 });
    gsap.set("#card8", { x: 50, opacity: 0 });

    gsap.to("#heading", {
      scrollTrigger: "#heading",
      y: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
    gsap.to("#card1", {
      scrollTrigger: "#card1",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
    gsap.to("#card3", {
      scrollTrigger: "#card3",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
    gsap.to("#card5", {
      scrollTrigger: "#card5",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
    gsap.to("#card7", {
      scrollTrigger: "#card5",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });

    gsap.to("#card2", {
      scrollTrigger: "#card2",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
    gsap.to("#card4", {
      scrollTrigger: "#card4",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
    gsap.to("#card6", {
      scrollTrigger: "#card6",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
    gsap.to("#card8", {
      scrollTrigger: "#card6",
      x: 0,
      opacity: 1,
      start: "top center",
      duration: 1.5,
      delay: 0.2,
      ease: "power2.out",
    });
  }, []);
  //animation ends

  return (
    // <!--...::: Feature Section Start :::... -->
    <section className="section-feature min-h-max">
      {/* <!-- Section Background --> */}
      <div className="relative z-10 bg-[#252525]">
        {/* <!-- Section Space --> */}
        <div className="section-space min-h-max">
          {/* <!-- Section Container --> */}
          <div className="container-default bg-[#252525]">
            {/* <!-- Section Content Wrapper --> */}
            <div className="jos mb-[60px] xl:mb-20">
              {/* <!-- Section Content Block --> */}
              <div className="mx-auto max-w-[636px]">
                <h2
                  className="mb-5 text-center font-Syne text-4xl font-semibold leading-[1.14] -tracking-[1px] text-[#F7F7F7] lg:text-5xl xl:text-[56px]"
                  id="heading"
                >
                  Our Services
                </h2>
              </div>
              {/* <!-- Section Content Block --> */}
            </div>
            {/* <!-- Section Content Wrapper --> */}

            {/* <!-- Feature List --> */}
            <div className="jos grid grid-cols-1 items-stretch gap-y-px bg-[#F7F7F7] lg:grid-cols-2 -my-10 lg:my-0">
              {/* Feature Items */}
              {[
                {
                  id: "card1",
                  icon: icon2Black,
                  title: "Web Development",
                  description:
                    "Our expert web development services encompass responsive design, custom functionality, and SEO optimization. We create user-friendly, high-performance websites using the latest technologies like React, Angular, and Vue.js to ensure your online presence stands out and drives business growth.",
                },
                {
                  id: "card3",
                  icon: icon1Black,
                  title: "Mobile App Development",
                  description:
                    "Transform your business with our expert mobile app development. We create powerful, user-friendly apps for iOS and Android, optimized for app store visibility. Our solutions combine cutting-edge technology with intuitive design to boost user engagement and drive your digital success.",
                },
                {
                  id: "card2",
                  icon: icon3Black,
                  title: "DevOps Solutions",
                  description:
                    "Accelerate your software delivery with our comprehensive DevOps solutions. We implement CI/CD pipelines, automate infrastructure, and integrate monitoring tools to enhance collaboration and system reliability. Our expertise ensures seamless scalability and efficient resource utilization.",
                },
                {
                  id: "card4",
                  icon: icon4Black,
                  title: "Cloud Development",
                  description:
                    "Empower your business with our expert cloud development services. We create scalable, secure solutions using AWS, Azure, and Google Cloud, optimizing performance and cost-efficiency for your digital transformation journey.",
                },
                {
                  id: "card5",
                  icon: icon5Black,
                  title: "Cloud-Native SaaS Applications",
                  description:
                    "We have a team of skilled developers who are experts in the latest app frameworks, like React, React Native, Node.js, Amazon Web Services, Docker, Kubernetes etc. We can help you build scalable applications that can grow with your business.",
                },
                {
                  id: "card6",
                  icon: icon6Black,
                  title: "Cloud Migration and Optimization",
                  description:
                    "Cloud is the biggest enabler of digital transformation. We help you adopt cloud technologies to accelerate your business. We are also authorized AWS Consulting Partner. Get in touch to know more.",
                },
                {
                  id: "card7",
                  icon: icon7Black,
                  title: "Cloud Infrastructure & Cost Optimization",
                  description:
                    "We help you assess, analyze and optimize your cloud infrastructure to reduce costs and improve performance. Embrace Infrastructure as Code to ensure consistency, scalability, and efficiency across your entire technology stack.",
                },
                {
                  id: "card8",
                  icon: icon8Black,
                  title: "Serverless Technologies",
                  description:
                    "Leverage our expertise in serverless technologies for rapid development and deployment. We minimize infrastructure management while maximizing cost efficiency, enabling you to focus on core business logic.",
                },
              ].map((item, index) => (
                <div
                  key={item.id}
                  className="relative flex h-full gap-x-5 bg-[#252525] py-10 after:absolute after:left-full after:top-1/2 after:z-10 after:hidden after:h-[100px] after:w-px after:-translate-y-1/2 after:bg-[#F7F7F7] even:after:hidden lg:p-10 lg:after:block lg:odd:after:block"
                >
                  <div className="h-[98px] w-[98px] flex-shrink-0" id={item.id}>
                    <img
                      src={item.icon}
                      alt={`icon-black-home-9-feature-${index + 1}`}
                      className="w-full h-full object-contain"
                      style={{
                        filter:
                          "invert(100%) sepia(6%) saturate(333%) hue-rotate(192deg) brightness(107%) contrast(88%)",
                      }}
                    />
                  </div>
                  <Link to="/service" className="flex-1">
                    <div className="flex-1" id={item.id}>
                      <div className="mb-[15px] font-Syne text-2xl font-semibold -tracking-[0.5px] text-[#F7F7F7]">
                        {item.title}
                      </div>
                      <p className="text-[#F7F7F7]">{item.description}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            {/* <!-- Feature List --> */}
          </div>
          {/* <!-- Section Container --> */}
        </div>
        {/* <!-- Section Space --> */}

        {/* <!-- Section Background Shape --> */}
      </div>
      {/* <!-- Section Background --> */}
    </section>
    // <!--...::: Feature Section End :::... -->
  );
};

export default Services;
