import { useLocation, Link } from 'react-router-dom';
import CareerForm from "./CareerForm";
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CareerDetails = () => {

  const location = useLocation();
  const job = location.state || {};
  console.log("🚀 ~ file: index.js:9 ~ CareerDetails ~ job:", job)

  return (
    <>
     <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      transition={Bounce}
    />
      <section className="section-career-details">
        <div className="section-space mt-28">
          <div className="container-default">
            <div className="grid items-start gap-10 lg:grid-cols-[1fr_minmax(0,_0.7fr)] xl:gap-[90px]">
              <div>
                <div className="mb-[18px]">
                  <div className="max-w-[526px]">
                    <h2 className="text-4xl font-semibold leading-[1.25] font-Syne">{job.title}</h2>
                  </div>
                </div>

                <div className="mb-[30px]">
                  <div className="my-5 flex flex-wrap gap-x-6 gap-y-3 text-ColorBlack md:my-[30px]">
                    <div className="flex gap-1">
                      <span>
                        <i className="fa-solid fa-clock"></i>
                      </span>
                      {job.employee_type}
                    </div>
                    <div className="flex gap-1">
                      <span>
                        <i className="fa-sharp fa-solid fa-location-dot"></i>
                      </span>
                      {job.type}
                    </div>
                    <div className="flex gap-1">
                      <span>
                        <i className="fa-solid fa-money-bills-simple"></i>
                      </span>
                      {`$${job.salary.min} - $${job.salary.max}`}
                    </div>
                  </div>
                </div>

                <article className="grid gap-y-5">
                  <h2 className="text-2xl font-semibold leading-[1.25]">
                    Job Overview
                  </h2>
                  <p className="mb-3">{job.description}</p>

                  <h2 className="text-2xl font-semibold leading-[1.25]">
                    Job Responsibilities
                  </h2>
                  <ul className="mb-5 flex list-inside list-disc flex-col gap-3 lg:mb-[30px]">
                    {job.jobresponsibilities.map((resp, index) => (
                      <li key={index}>{resp}</li>
                    ))}
                  </ul>

                  <h2 className="text-2xl font-semibold leading-[1.25]">
                    Requirements
                  </h2>
                  <ul className="mb-5 flex list-inside list-disc flex-col gap-3 lg:mb-[30px]">
                    {job.jobrequirements.map((req, index) => (
                      <li key={index}>{req}</li>
                    ))}
                  </ul>

                  <h2 className="text-2xl font-semibold leading-[1.25]">
                    Skills & Experience
                  </h2>
                  <div className="flex flex-wrap gap-1 mt-1">
                    {job.skills_required.map((skill, index) => (
                      <span
                        key={index}
                        className="bg-gray-100 text-ColorBlack px-3 py-1 rounded-lg text-lg font-medium"
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                </article>
              </div>

              <CareerForm data={job._id} />
            </div>
            <div className="flex justify-center mt-10">

              <Link to={'/career'} className="btn bg-[#52818b] text-white hover:text-[#52818b] hover:border hover:border-[#52818b] border is-large is-rounded btn-s group inline-block btn-animation"><span>Other Job Posts</span></Link>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerDetails;
