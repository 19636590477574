import React from 'react';
import { Link } from 'react-router-dom';
import whitelogo from '../../assets/img/logos/white.webp';


const Footer = () => {
  // Preload fonts to reduce layout shifts
  React.useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = 'path/to/Syne-SemiBold.woff2'; // Update with actual path
    link.as = 'font';
    link.type = 'font/woff2';
    link.crossOrigin = 'anonymous';
    document.head.appendChild(link);
    
    const linkRegular = document.createElement('link');
    linkRegular.rel = 'preload';
    linkRegular.href = 'path/to/Syne-Regular.woff2'; // Update with actual path
    linkRegular.as = 'font';
    linkRegular.type = 'font/woff2';
    linkRegular.crossOrigin = 'anonymous';
    document.head.appendChild(linkRegular);
  }, []);
  
  const socialMediaLinks = [
    { url: 'https://twitter.com', icon: 'fa-brands fa-twitter' },
    { url: 'https://facebook.com', icon: 'fa-brands fa-facebook-f' },
    { url: 'https://instagram.com', icon: 'fa-brands fa-instagram' },
    { url: 'https://github.com', icon: 'fa-brands fa-github' },
  ];

  return (
    <footer className="section-footer bg-[#252525] text-white min-h-[200px]">
      <div className="py-12 lg:py-20">
        <div className="container-default text-center md:text-initial">
          <div className="flex flex-col gap-10 lg:gap-initial lg:flex-row lg:justify-between">
            <div className="flex flex-col gap-10 mb-10 md:mb-0">
              <Link to="/" className="self-center lg:self-start">
                <img src={whitelogo} alt="Footer Logo" width="200" height='53px'/>
              </Link>
              <p className="font-Syne text-center lg:text-left lg:max-w-[416px]">
                We are a leading IT solutions provider specializing in custom software development, cloud services, and digital transformation. Our expertise ensures your business stays ahead in the digital era.
              </p>
            </div>
            <div className="flex flex-col gap-y-7 mb-10 md:mb-0">
              <div className="text-xl font-semibold capitalize font-Syne">Quick Links</div>
              <ul className="flex flex-col gap-y-2 capitalize font-Syne">
                <li>
                  <Link to={'/about'} className="hover:opacity-100 underline-offset-4 opacity-80 transition-all duration-150 ease-linear hover:text-[#53818b]">About</Link>
                </li>
                <li>
                  <Link to={'/contact'} className="hover:opacity-100 underline-offset-4 opacity-80 transition-all duration-150 ease-linear hover:text-[#53818b]">Contact</Link>
                </li>
                <li>
                  <Link to="pricing.html" className="hover:opacity-100 underline-offset-4 opacity-80 transition-all duration-150 ease-linear hover:text-[#53818b]">Support</Link>
                </li>
                <li>
                  <Link to="contact.html" className="hover:opacity-100 underline-offset-4 opacity-80 transition-all duration-150 ease-linear hover:text-[#53818b]">Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col  items-center lg:items-start">
              <Link to="mailto:contact@codelamda.com" className="font-Syne mb-6 block underline-offset-4 transition-all duration-300 hover:underline">contact@codelamda.com</Link>
              <div className="flex flex-wrap gap-5">
                {socialMediaLinks.map((item) => (
                  <Link key={item.url} to={item.url} target="_blank" rel="noopener noreferrer" className="flex h-8 w-8 items-center justify-center rounded-full bg-white bg-opacity-5 text-sm text-white transition-all duration-300 hover:bg-[#53818b]" aria-label="social media link">
                    <i className={item.icon}></i>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white bg-opacity-5 py-4">
        <div className="container-default text-center text-opacity-80 text-base font-Syne">
          &copy; Copyright 2024, All Rights Reserved by Codelamda
        </div>
      </div>
    </footer>
  );
};

export default Footer;