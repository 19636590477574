import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect } from 'react';

import mission1 from '../../assets/img/icons/about/mission_1.webp';
import mission2 from '../../assets/img/icons/about/mission_2.webp';
import mission3 from '../../assets/img/icons/about/mission_3.webp';

gsap.registerPlugin(ScrollTrigger);

const missionData = [
  {
    icon: mission1,
    title: 'Our Mission: Empowering Digital Transformation',
    content: 'We empower businesses through innovative software solutions, driving digital transformation and sustainable growth in the ever-evolving tech landscape.'
  },
  {
    icon: mission2,
    title: 'Our Vision: Leading Software Development Innovation',
    content: 'To be the global leader in cutting-edge software development, recognized for our commitment to excellence, innovation, and client success in the digital era.'
  },
  {
    icon: mission3,
    title: 'Our Core Values: Excellence in Software Development',
    content: 'We are committed to innovation-driven excellence in technology, delivering client-centric software solutions through agile development collaboration. We prioritize continuous learning in tech and uphold ethical integrity in all our digital solutions, ensuring we remain at the forefront of the software development industry.'
  }
];

const MissionCard = ({ icon, title, content, index }) => (
  <article className={`text-center p-3 py-6 md:p-3 lg:p-3 xl:p-4 xxl:p-5`}>
    <img src={icon} alt={`${title} icon - software development excellence`} className="mx-auto mb-4 h-16 w-16" />
    <h4 className="text-2xl font-semibold mb-4 font-Syne">{title}</h4>
    <p className="text-gray-700">{content}</p>
  </article>
);

const Mission = () => {
  useEffect(() => {
    const animateElements = (selector, yStart, delay) => {
      gsap.set(selector, { y: yStart, opacity: 0 });
      gsap.to(selector, {
        scrollTrigger: selector,
        y: 0,
        opacity: 1,
        start: "top center",
        duration: 1.5,
        delay,
        ease: "power2.out",
      });
    };

    animateElements("#mission-heading", 30, 0.2);
    animateElements("#mission-content", 30, 0.4);
  }, []);

  return (
    <section className="bg-[#F7F7F7] py-20">
      <div className="container mx-auto px-4">
        <h1 id="mission-heading" className="text-center text-4xl md:text-5xl font-Syne font-semibold mb-14">
          Our Mission, Vision, and Core Values
        </h1>
        <div id="mission-content" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 divide-y sm:divide-y md:divide-y-0">
          {missionData.map((item, index) => (
            <React.Fragment key={index}>
              <MissionCard {...item} index={index} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Mission;